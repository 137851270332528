//import logo from './logo.svg';
import './App.css';

import LeftMenu from './components/menu/LeftMenu';

import CupPage from './pages/CupPage/CupPage';
import ArbitrationPage from './pages/ArbitrationPage/ArbitrationPage';
import FinishGamePage from './pages/FinishGamePage/FinishGamePage';
import AuthPage from './pages/AuthPage';
import HomePage from './pages/HomePage';
import TeamsPage from './pages/TeamsPage/TeamsPage';
import TeamPage from './pages/TeamPage/TeamPage';
import CreateCupPage from './pages/CreateCupPage/CreateCupPage';
import CreateContestPage from './pages/CreateContestPage/CreateContestPage';
import ImportTeamPage from './pages/ImportTeamPage/ImportTeamPage';

import CreateTeamPage from './pages/CreateTeamPage/CreateTeamPage';
import SubscribePlayerPage from './pages/SubscribePlayerPage/SubscribePlayerPage';

import {
  HOME,
  AUTH,
  IMPORT_TEAM,
  CREATE_CUP,
  SUBSCRIBE_PLAYER,
  CREATE_CONTEST,
  TEAMS,
  TEAM,
  GAME,
  ARBITRATION,
  CREATE_TEAM,
  CUP
} from './constant/routes.js';

import {
  BrowserRouter,
  Routes as Switch,
  useLocation,
  Route,
  matchPath
} from "react-router-dom";
import { useState, useEffect } from 'react';

function App() {

  const [games, setGames] = useState([])
  const [user, setUser] = useState({})
  
  const [contestid, setContestid] = useState(2);
  const [cupid, setCupid] = useState(2);
  const [cup, setCup] = useState({})
  const loc = useLocation()
  const match = matchPath({ path: SUBSCRIBE_PLAYER}, loc.pathname)
  const isPanelRendering = match || loc.pathname === HOME || loc.pathname == AUTH || loc.pathname == CREATE_CUP
  useEffect(() => {
    document.title = 'Aplicacão de copa';
    document.description = 'Crie e organize campeonatos';
  }, []);

  if(Object.keys(user).length == 0) {
    return <AuthPage setUser={setUser}/>
  }

  return (
    <div className="app">
      { !isPanelRendering && <LeftMenu cup={cup} cupid={cupid} />}
      <Switch>
        <Route  path={ HOME } element={ <HomePage user={user} setCup={setCup} setCupid={setCupid}/>} />
        <Route  path={ AUTH } element={ <AuthPage setUser={setUser} />} />
        <Route  path={ CUP  } element={ 
          <CupPage 
            games={games}
            setGames={setGames}
          /> 
        } />



        <Route  path={ TEAMS } element={ <TeamsPage /> } />
        <Route  path={ ARBITRATION } element={ <ArbitrationPage /> } />
        <Route  path={ SUBSCRIBE_PLAYER } element={ <SubscribePlayerPage /> } />
        <Route  path={ IMPORT_TEAM } element={ <ImportTeamPage cupid={cupid} user={user} /> } />
        <Route  path={ TEAM } element={ <TeamPage /> } />
        
        <Route  path={ GAME } element={
          <FinishGamePage
            setGames={setGames}
            games={games}
          />
        } />

        <Route  path={ CREATE_CUP } element={ <CreateCupPage setCupid={setCupid} setCup={setCup}/> } />
        <Route  path={ CREATE_TEAM } element={ <CreateTeamPage /> } />
        <Route  path={ CREATE_CONTEST } element={ <CreateContestPage /> } />
      </Switch>
    </div>
  );
}

export default App;
