import './Contest.css';
import { HOST } from '../../constant/constant';


import { useState, useEffect } from 'react';
import axios from 'axios';
import SimpleButton from '../buttons/SimpleButton';
import RiffleGames from '../RiffleGames/RiffleGames';
import OrganizeTableContainer from '../OrganizeTableContainer/OrganizeTableContainer';
import Modal from 'react-modal';

const Table = (props) => {
  return (
    <div class="table-c">
      <h1>Grupo {String.fromCharCode(props.g+65)}</h1>
      <tbody>
        <tr className="">
          <td>Nº</td>
          <td className="table-team">Clube</td>
          <td>Pts</td>
          <td>PJ</td>
          <td>VIT</td>
          <td>E</td>
          <td>DER</td>
          <td>GM</td>
          <td>GC</td>
          <td>SG</td>
        </tr>
        <TableRows rows={props.rows} />
      </tbody>
      <div className="table-vc"></div>
    </div>
  )
}

const TableRows = (props) => {
  return (
     
      props.rows.map((r, i) => {
        return (
          <tr>
            <td className="td-team">
              <div className="td-team-n">
                <span>{i+1}</span>
              </div>
              <div className="td-team-img">
                <img src={HOST+"/public/image/"+r.picture} />
              </div>
            </td>
            <td className="table-team-v">
              {r.name ? r.name : '-'}
            </td>

            <td>{r.points ? r.points : '-'}</td>
            <td>{r.pj ? r.pj : '-'}</td>
            <td>{r.wins ? r.wins : '-'}</td>
            <td>{r.ties ? r.ties : '-'}</td>
            <td>{r.loses ? r.loses : '-'}</td>
            <td>{r.gm ? r.gm : '-'}</td>
            <td>{r.gc ? r.gc : '-'}</td>
            <td>{r.sg ? r.sg : '-'}</td>
          </tr>
        );
      })
    
    );
}

const GruposMataContest = (props) => {

  const [loading, setLoading] = useState(true)

  var [tables, setTables] = useState([])

  useEffect(() => {
    console.log("REGISTRED TEAMS TUA PUTA")
    console.log(props.registredTeams);
    loadTable()
  }, [])
 
  const loadTable = () => {
    Object.values(props.table).forEach((v, k) => {
      tables.push({ rows: v, g: k})
    })
    setLoading(false)
  }

  const addRegistredTeams = (tableId, indexId, data) => {
    console.log('adding on '+tableId+' | '+ indexId)
    props.registredTeams[tableId][indexId] = data;
    var t = {...props.registredTeams};
    t[tableId][indexId] = data;

    props.setRegistredTeams(t);
    console.log("INSERT IS MADE")
    console.log(props.registredTeams)
  }
  const removeRegistredTeams = (tableid, indexid) => {
    var t = {...props.registredTeams}
    t[tableid][indexid] = -1;
    console.log("feito em: removeRegistredTeams: "+tableid+" ,"+indexid)
    console.log(t)
    props.setRegistredTeams(t)
  }

  const state = props.contest.state;
  const [organizeTable, setOrganizeTable] = useState(false);

  if(loading) {
    return <h1>Loading...</h1>;
  }

  console.log('REGISTRED TEAMS');
  console.log(props.registredTeams)

  return (
    <div className="contest">

      {state == 0 ? (
        <SimpleButton 
          onClick={() => setOrganizeTable(true)}
          text="Organizar tabela(s)"
        />
      ) : null
      }

      <OrganizeTableContainer
        tables={tables}
        organizeTable={organizeTable}
        contest={props.contest}
        setOrganizeTable={setOrganizeTable}
        addRegistredTeams={addRegistredTeams}
        removeRegistredTeams={removeRegistredTeams}
        readyRegistredTeams={props.readyRegistredTeams}
        registredTeams={props.registredTeams}
      />

      {
        Object.keys(props.registredTeams).map((v, k) => {
          return <Table rows={props.registredTeams[v]} g={k}/>
        }) 
      }
    
    </div>
  );
};

export default GruposMataContest;
