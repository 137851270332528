import './FinishGameInput.css'
import Toggle from '../Toggle/Toggle'; 

const FinishGameInput = (props) => {
  const player = props.player;
  console.log("player: ")
  console.log(player)
  
  const classJsonData = JSON.stringify({ 
    playerid: props.player.playerid,
    team: props.team,
    teamid: player.teamid 
  })

  return (
    <tbody class="fgame-inputs">
      <tr>
        <td className="name">
          <span>{player.name}</span>
        </td>
        <td>
          <span>{player.birth}</span>
        </td>
        <td>
          <Toggle q={true} />
        </td>
          
        <td>
          <div className="fgm-inpt">
            <div>
              <button 
                onClick={() => props.incrementInput(player.id, 'goals', props.teamtype)}
                >+</button>
            </div>
          {
            props.teamdata['goals'][player.id] && (
              <div className="fgm-decinpt">
                <div className="fgm-decinpt-spn">
                  <span>{props.teamdata['goals'][player.id]}</span>
                </div>
                <div className="fgm-decinpt-btn">
                  <button 
                    onClick={() => props.decrementInput(player.id, 'goals', props.teamtype)}
                    >-</button>
                </div>
              </div>
            )
          }
          </div>
        </td>

        <td>
          <div className="fgm-inpt">
            <div>
              <button 
                onClick={() => props.incrementInput(player.id, 'assists', props.teamtype)}
                >+</button>
            </div>
          {
            props.teamdata['assists'][player.id] && (
              <div className="fgm-decinpt">
                <div className="fgm-decinpt-spn">
                  <span>{props.teamdata['assists'][player.id]}</span>
                </div>
                <div className="fgm-decinpt-btn">
                  <button 
                    onClick={() => props.decrementInput(player.id, 'assists', props.teamtype)}
                    >-</button>
                </div>
              </div>
            )
          }
          </div>
        </td>

        <td>
          <div className="fgm-inpt">
            <div>
              <button 
                onClick={() => props.incrementInput(player.id, 'cards', props.teamtype)}
                >+</button>
            </div>
          {
            props.teamdata['cards'][player.id] && (
              <div className="fgm-decinpt">
                <div className="fgm-decinpt-spn">
                  <span>{props.teamdata['cards'][player.id]}</span>
                </div>
                <div className="fgm-decinpt-btn">
                  <button 
                    onClick={() => props.decrementInput(player.id, 'cards', props.teamtype)}
                    >-</button>
                </div>
              </div>
            )
          }
          </div>
        </td>
        </tr>
      </tbody>
  );
}

/*
        <input name="yellows" className={
          JSON.stringify({ playerid: player.playerid, team: props.team, teamid: player.teamid })} 
          type="number" placeholder="cartoes" />

        <input name="assists" className={
          JSON.stringify({ playerid: player.playerid, team: props.team, teamid: player.teamid })} 

*/

export default FinishGameInput;
