import './BorderButton.css';

const BorderButton = (props) => {
  return (
    <div className="border-button">
      <button style={props.style} 
      onClick={props.onClick}>{props.children} {props.text}</button>
    </div>
  );

}

export default BorderButton;
