import './RiffleGames.css';
import axios from 'axios';

import { useState, useEffect } from 'react';
import Modal from 'react-modal';

import SimpleButton from '../buttons/SimpleButton'
import FormButton from '../buttons/FormButton'

const RiffleGames = (props) => {
  
  const [riffleGames, setRiffleGames] = useState(false)
  const [error, setError] = useState("")

  const onRiffleGruposMata = async () => {

    var c = 0;
    for(var i in props.registredTeams) {
      for(var j in props.registredTeams[i]) {
        if(props.registredTeams[i][j] != -1) {
          c += 1
        }
      }
    }
    console.log(props.registredTeams)

    var x = (props.contest.groupsAmount*props.contest.teamsAmount)-c;
    if(x > 0) {
      setError("Faltam "+x+" times serem registrados na tabela para permitir sorteamento de jogos")
      console.log("Error")
      return
    }

    var games = []
    for(var i in props.registredTeams) {
      var t = [];
      var k = [...props.registredTeams[i]];

      console.log("k")
      console.log(k)


      var c = k.length;
      console.log("c")
      console.log(c)
      var gamesPerRound = (c*(c-1) / 2)


      t[0] = k[0]
      if(c % 2 == 0) {
        gamesPerRound = gamesPerRound / (c-1)
      console.log("gamesPerRound:")
      console.log(gamesPerRound)
        for(var j = 0; j < k.length-1; j++) {
          for(var z = 1; z < props.registredTeams[i].length-1; z++) {
            t[z] = k[z+1];
          }
          t[k.length-1] = k[1];
          k = [...t];
          for(var q = 0; q < gamesPerRound; q++) {
            games.push({
              'team1': k[q].teamid ? k[q].teamid : k[q].id,
              'team2': k[(k.length-1)-q].teamid ? k[(k.length-1)-q].teamid : k[(k.length-1)-q].id,
              'contestid': props.contest.id
            })
            console.log("adicionado games:")
            console.log(games)
          }

        }
      } else {
        gamesPerRound = gamesPerRound / c
      console.log("gamesPerRound:")
      console.log(gamesPerRound)
        for(var j = 0; j < k.length; j++) {
          for(var z = 0; z < k.length-1; z++) {
            t[z] = k[z+1];
          }
          t[k.length-1] = k[0];
          k = [...t]
          for(var q = 1; q <= gamesPerRound; q++) {
            games.push({
              'team1': k[q].teamid ? k[q].teamid : k[q].id,
              'team2': k[(k.length-q)].teamid ? k[(k.length-q)].teamid : k[( k.length - q)].id,
              'contestid': props.contest.id
            })
            console.log("adicionado games:")
            console.log(games)
          }
        }


      }
    }

    console.log(games)
    props.setGames(games)
    var vc = {...props.contest};
    vc.state = 1;
    props.setContest(vc)
    axios.post('/api/campeonatos/'+props.contest.id+'/jogos', games);
    setRiffleGames(false)
  }

  const onRiffleGames = {
    2: onRiffleGruposMata,
    0: onRiffleGruposMata,
    1: onRiffleGruposMata
  }

  const riffleGamesModalStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.29)',
    },
    content: {
      maxWidth: '359px',
      maxHeight: '250px',
      textAlign: 'center',
      margin: 'auto',
      border: 'none',
      backgroundColor: '#141b2d'//'rgba(0,0,0,.35)'
    }
  }

  return (
    <div className="riffle-games">
      <Modal isOpen={riffleGames} 
              style={riffleGamesModalStyle}
              onRequestClose={() => setRiffleGames(!riffleGames)}>
      
        <h1 style={{ textAlign: 'center' }}>Sortear Jogos</h1>
          <div className="error">
            {error != "" ? <span>{error}</span>: null}
          </div>
          
          <FormButton 
            onClick={ onRiffleGruposMata/*onRiffleGames[props.contest.format]*/}
            text="Sortear"
          />
      </Modal>
      <SimpleButton 
        onClick={ () => setRiffleGames(true) }
        text="Sortear jogos"
      />
    </div>
  );

}

export default RiffleGames;
