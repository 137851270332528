
const BACKGROUND_STYLE = {
  position: 'fixed',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
  zIndex: '1000',
  background: 'rgb(0, 0, 0, 0.7)'
};

const MODEL_STYLE = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: 'rgba(50,138,241,.13)',
  padding: '50px'
};

const Modal = ({ isOpen, setOpenModal, children  }) => {
  if(isOpen) {
    return (
      <div style={BACKGROUND_STYLE}>
        <div style={MODEL_STYLE}>
          { children }
          <button onClick={ setOpenModal} >close</button>
        </div>

      </div>
    );
  }

  return null;
}

export default Modal;
