import ContestCreatorForm from '../../components/forms/ContestCreatorForm'
import './CreateContest.css';
import '../Page.css';
import { useParams } from 'react-router-dom';

const CreateContestPage = () => {

  const { cupid } = useParams();
  
  return (
    <div className="gpage" style={{ marginTop: '25px' }}>

     <ContestCreatorForm 
        cupid={cupid}
     /> 

    </div>
  );

}

export default CreateContestPage;
