import './FormButton.css';

const SimpleButton = (props) => {

  return (
    <div className="form-button">
      <button style={props.style} 
      onClick={props.onClick}>{props.text}</button>
    </div>
  );

}

export default SimpleButton;
