import '../Page.css';
import { useLocation ,useParams } from 'react-router-dom';

import Arbitrations from '../../components/Arbitrations/Arbitrations';

const ArbitrationPage = () => {
  
  const { cupid } = useParams();

  return (
    <div className="gpage">
     <Arbitrations cupid={cupid} />
    </div>
  );
}

export default ArbitrationPage;
