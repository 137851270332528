export const HOME = "/";
export const GAME= "/copas/:cupid/jogos/:gameid";
export const AUTH = "/autenticacao";
export const CREATE_CUP = "/copas/novo";
export const CUP = "/copas/:cupid"
export const ARBITRATION = "/copas/:cupid/arbitragens"
export const IMPORT_TEAM = "/campeonatos/:contestid/times/importar"
export const SUBSCRIBE_PLAYER = "/campeonatos/:contestid/times/:teamid/inscrever"
export const CREATE_CONTEST = "/copas/:cupid/campeonatos/criar"
export const TEAMS = "/copas/:cupid/times"
export const TEAM = "/campeonatos/:contestid/times/:teamid"
export const CREATE_TEAM = "/copas/:cupid/times/criar"
