import './Cup.css';
import { HOST } from '../../constant/constant';
import { Link } from 'react-router-dom';

const Cup = (props) => {

  const updateCup = () => {
    props.setCupid(props.cup.id)
    props.setCup(props.cup)
  }

  return (
    <Link to={"/copas/"+props.cup.id} onClick={ updateCup  }>
      <div className="cup">
        <div className="cup-pic">
          <img src={HOST+"/public/image/"+props.cup.picture} />
        </div>
        <div className="cup-info">
          <span>{props.cup.name}</span>
          <div className="cup-info-teams">
            <span>alguma coisa</span>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default Cup;
