import CreatorForm from '../../components/forms/CreatorForm';
import './CreateCupPage.css';

const CreateCupPage = (props) => {

  return (
    <div className="gpage">
      <div className="createCup-container">
        <CreatorForm setCup={props.setCup} setCupid={props.setCupid}  title="CRIE SUA COPA"/>
      </div>
    </div>
  );

};

export default CreateCupPage;
