import FinishGameInput from '../FinishGameInput/FinishGameInput';
import './FinishGameForm.css'
import SimpleButton from '../buttons/SimpleButton';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

const FinishGameForm = (props) => {

  const team1 = props.teams[0]
  const team2 = props.teams[1]
  
  const [team1players, setTeam1Players] = useState([]);
  const [team2players, setTeam2Players] = useState([]);

  const [team1data, setTeam1data] = useState({ id: team1.id, goals: {}, assists: {}, cards: {} });
  const [team2data, setTeam2data] = useState({ id: team2.id, goals: {}, assists: {}, cards: {} });


  const [team1goals, setTeam1goals] = useState(0)
  const [team2goals, setTeam2goals] = useState(0)

  const [loading, setLoading] = useState(true);
  const [teamFormState, setTeamFormState] = useState(true)

  let navigate = useNavigate();

  /*const loadTeamsPlayer = async (teamid) => {
    var data = await axios.get('/api/campeonatos/'+props.game.contestid+'/times/'+props.game.team1+'/jogadores');
    return data.data
  }*/

  const loadTeamsPlayers = async () => {
    axios.get('/api/campeonatos/'+props.game.contestid+'/times/'+team1.id+'/jogadores/disponiveis').then(response => {
      response.data['team'] = 'team1';
      setTeam1Players(response.data)
      console.log("v "+ response.data['team'])
    });

    axios.get('/api/campeonatos/'+props.game.contestid+'/times/'+team2.id+'/jogadores/disponiveis').then(response => {
      response.data['team'] = 'team2';
      setTeam2Players(response.data)
    });

    setLoading(false)
  }

  const onFormSubmit = (e) => {
    
    e.preventDefault()
    /*var yellows = {...team1data['cards'], ...team2data['cards']}
    var data = {
      
    }
    var data = {
      'yellows': {}
    }
    for(var i in e.target.elements) {
      var input = e.target.elements[i]
      console.log("input:")
      console.log(input.id)

      if(e.target.elements[i].className == "")
        break;
      
      var player = JSON.parse(e.target.elements[i].className)
      if(!data[player.team]) {
        data[player.team] = { 
          id: player.teamid,
          'goals': {},
          'assists': {}};
      }

      if(input.value != "") {
        if(input.name == "goals") {
          data[player.team]['goals'][player.playerid] = Number(input.value)
        }

        if(input.name == "yellows") {
          data['yellows'][player.playerid] = Number(input.value)
        }

        if(input.name == "assists") {
          data[player.team]['assists'][player.playerid] = Number(input.value)
        }
      }
    }
   axios.post('/api/jogos/'+props.game.id+'/finalizar', data);
    
    console.log(data)
    return navigate('/copas/'+props.cupid)
  */
  }

  const sendTeamsData = () => {
    var data = {yellows: {}}
    for(var i in team1data['cards']) {
      data['yellows'][i] = team1data['cards'][i];
    }
    
    for(var i in team2data['cards']) {
      data['yellows'][i] = team2data['cards'][i];
    }

    data['team1'] = team1data;
    data['team2'] = team2data;
   
    axios.post('/api/jogos/'+props.game.id+'/finalizar', data);

   console.log("SENDING THAT SHIT:")
   console.log(data)
   return navigate('/copas/'+props.cupid+'?contestid='+props.game.contestid);
  }

  useEffect(() => {
    loadTeamsPlayers()
  }, [])

  if(loading) {
    return <h1>Loading..</h1>
  }

  console.log("teams")
  console.log(props.teams)

  console.log("team1players")
  console.log(team1players.team)

  const incrementInput = (playerid, index, type) => {
    console.log("index")
    console.log(index)
    var x = type ? {...team1data} : {...team2data};
    if(x[index][playerid]) {
      if(index == 'goals') {
        type ? setTeam1goals(team1goals + 1) : setTeam2goals(team2goals + 1)
      }
      x[index][playerid] += 1;
    } else {
      if(index == 'goals') {
        type ? setTeam1goals(team1goals + 1) : setTeam2goals(team2goals + 1)
      }
      x[index][playerid] = 1;
    }
    type ? setTeam1data(x) : setTeam2data(x)
    console.log('x')
    console.log(x)
  }

  const decrementInput = (playerid, index, type) => {
    var x = type ? {...team1data} : {...team2data};
    if(x[index][playerid]) {
      if(index == 'goals') {
        type ? setTeam1goals(team1goals - 1) : setTeam2goals(team2goals - 1)
      }
      
      if(x[index][playerid] <= 1) {
        delete x[index][playerid];
      } else {
        x[index][playerid] -= 1;
      }

    } else {
      
    }

    type ? setTeam1data(x) : setTeam2data(x)
  }

  return (
    <div className="finishGame">
      <div className="versus">
        <div className="versus-i">
          <img src={"/public/image/"+team1.picture}/>
        </div>
        <div className="vs-finish">
          <div className="v-score">
            <span>{team1goals}</span>
          </div>
          
          <div className="v-finish">
            <span>VS</span>
          </div>
          <div className="v-score">
            <span>{team2goals}</span>
          </div>
        </div>

        <div className="versus-i">
          <img src={"/public/image/"+team2.picture}/>
        </div>
      </div>
      <div className="changeTeamForm">
        <div className="changeTeamForm-i"
             style={ teamFormState ? { borderBottom: '5px solid #1d244a'} : null}
             onClick={ () => { setTeamFormState(true) }}
        >
          <span>{team1.name}</span>
        </div>
        <div className="changeTeamForm-i"
             onClick={ () => { setTeamFormState(false) }}
             style={ !teamFormState ? { borderBottom: '5px solid #1d244a'} : null}>
          <span>{team2.name}</span>
        </div>
      </div>

      <form onSubmit={onFormSubmit}>
        <table cellspacing="0">

          <div style={{ display: teamFormState ? 'block' : 'none' }}>
          <tbody style={{ background: '#0c1628' }}>
          <tr className="changeTeamForm-head">
            <td className="name">Nome</td>
            <td>Nasc.</td>
            <td>Participa��o</td>
            <td>Gols</td>
            <td>Assist�ncia</td>
            <td>C. Amarelos</td>
          </tr>
          </tbody >
          {
            team1players.map((v) => {
              return <FinishGameInput
                player={v}
                teamdata={team1data}
                incrementInput={incrementInput}
                decrementInput={decrementInput}
                teamtype={true}
                team={team1players.team}
              />
            })
          }
        </div>

        <div style={{ display: !teamFormState ? 'block' : 'none' }}>
          <tbody style={{ background: '#0c1628' }}>
          <tr className="changeTeamForm-head">
            <td className="name">Nome</td>
            <td>Nasc.</td>
            <td>Participa��o </td>
            <td>Gols</td>
            <td>Assist�ncia</td>
            <td>C. Amarelos</td>
          </tr>
          </tbody >
          {
            team2players.map((v) => {
              return <FinishGameInput
                player={v}
                teamdata={team2data}
                incrementInput={incrementInput}
                decrementInput={decrementInput}
                teamtype={false}
                team={team2players.team}
              />
            })
          }
        </div>
        </table>
        
        <SimpleButton
          style={{
            marginTop: '25px',
            width: '90%',
            fontSize: '1.2em',
            fontWeight: 'normal',
            background: '#0b9b1b'
          }}
          onClick={sendTeamsData}
          text="Finalizar jogo"
        />
      </form>
    </div>
  )
}

export default FinishGameForm;
