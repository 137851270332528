import './CreatorForm.css'
import FormButton from '../../components/buttons/FormButton';
import { onFormInput } from '../../utils/FormInput.js'
import { useState } from 'react';
import ContestCreatorInputs from './ContestCreatorInputs';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

const ExtraOptions = (props) => {
  console.log(props.value == 2)
  console.log("value:"+props.value)
  if(props.value == 3) {
    return (  
      <div className="creatorForm-inputs-flex">
        <input name="teamsAmount" className="int" type="number" placeholder="numero times" />
        <input name="groupsAmount" className="int" type="number" placeholder="numero grupos" />
      </div>
    );
  } else {
    return (  
      <div className="creatorForm-inputs-flex">
        <input className="int" name="teamsAmount" type="number" placeholder="numero times" />
      </div>
    );
  }

}

const ContestCreatorForm = (props) => {

  let navigate = useNavigate();
  const [value, setValue] = useState(0); 
  
  const onFormSubmit =  (event) => {
    var data = onFormInput(event) 
    console.log(data)
  
    axios.post('/api/copas/'+props.cupid+'/campeonatos', data).then(response => {
      if(response.data.error) {
        console.log("Error on craete campeonato");
      }
      return navigate('/copas/'+props.cupid)
    });
  };
  const onChange = (event) => {
    console.log(event.target.value);
    setValue(event.target.value);
  };

  return (
    <div className="add-team" style={{ width: '454px' }}>
      <div className="creatorForm">

       <div className="creatorForm-title">
          <h1 style={{ textAlign: 'left', marginLeft: '17px', fontSize: '1.5rem', marginTop: '6px'}}>Criar nova categoria</h1>
       </div>

        <form onSubmit={onFormSubmit} style={{ marginTop: '-30px' }}>
          <ContestCreatorInputs />

          <FormButton text="CRIAR CATEGORIA" />
        </form>
      </div>
    </div>
  );
}

export default ContestCreatorForm;

