const getFormRequiredData = (event) => {
  var count = 0;
  let data = {};

  for(var input of event.target.elements) {
    if(input.type === "submit") continue;
    if(input.value === "") break;
    console.log(input.className)
    if(input.className == "int") {
      data[input.name] = parseInt(input.value);
    } else {
      data[input.name] = input.value;
    }
    count += 1;
  }

  return count > 0 ? data : null;
};

const getFormOptionalData = (event) => {
  var count = 0;
  let data = {};

  for(var input of event.target.elements) {
    if(input.type === "submit" || input.value === "") continue;
    data[input.name] = input.value;
    count += 1;
  }

  return count > 0 ? data : null;
};


export const onFormInput = (event, allRequired = true) => {
  event.preventDefault()
  if(allRequired) {
    return getFormRequiredData(event);
  } else {
    return getFormOptionalData(event);
  }
}
