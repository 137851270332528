import Modal from 'react-modal';
import { ReactComponent as TrashSvg } from '../Players/Trash.svg';
import { useState, useEffect } from 'react';
import axios from 'axios';

var x = false
var lax = -1
const OrganizeTableContainer = (props) => {
  
  let teams = []
  /*
    [{ teamid: 12, tableid: 12 }]
  */
  const [tempRegistredTeams, setTempRegistredTeams] = useState([]);
  const [tempUnregistredTeams, setTempUnregistredTeams] = useState([]);

  const [unregistredTeams, setUnregistredTeams] = useState([]);
  const [loading, setLoading] = useState(true)
  const [teamsDefaultValue, setTeamsDefaultValue] = useState({})
  
  const Table = (props) => {
    lax += 1
    return (
      <div className="table-c">
        <h1>Grupo {String.fromCharCode(lax+65)} </h1>
        <tbody>
          <td className="table-team">Clube</td>
          <td>Pts</td>
          <td>PJ</td>
          <td>VIT</td>
          <td>E</td>
          <td>DER</td>
          <td>GM</td>
          <td>GC</td>
          <td>SG</td>
          {
            props.rows.map((v, k) => {
              return <TableRow 
                      tableid={props.tableid}
                      rowIndex={k}
                      unregistredTeams={props.unregistredTeams}
                      row={v} />
            })
          }
        </tbody>
      </div>
    );
  }

  const TableRow = (props) => {
    console.log(props.unregistredTeams)
    if(!props.row || props.row == -1) {
      return (
        <tr>
          <td>
            <select className="select-input" onChange={registerTeam}>
             <option>nenhum</option>
             {
              unregistredTeams.map((v, k) => {
                 return v ? <option value={ JSON.stringify({ 
                    tableid: Number(props.tableid),
                    tableIndex: Number(props.rowIndex),
                    teamKey: Number(k),
                    rowIndex: Number(k)}) }>{v.name}</option> : null
               })
              }
            </select>
          </td>

          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        );
    } else {

      return (
        <tr>
          <td>{ props.row.name }
            <button className="svgbtn" onClick={unRegisterTeam} value={ JSON.stringify({
              tableid: Number(props.tableid),
              tableIndex: Number(props.rowIndex)})}>R</button>
          </td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
      );
    }
  }

  const loadTeams = async () => {
    unregistredTeams.splice(0, unregistredTeams.length)
    var rag = {...props.registredTeams};
    await axios.get('/api/campeonatos/'+props.contest.id+'/times').then(response => {
      teams = response.data;
      var t = false;
      /* for em cada time do campeonato*/
      for(var i in teams) {
        /* obter cada time dos times das tabelas registradas no campeonato*/
        var isTeamRegistred = false;
        for(var j in props.registredTeams) {

          var rg = props.registredTeams[j]
          /* comparar todos times requisitados com times da tabela */
          
          for(var k in rg) {

            if(props.registredTeams[j][k] == -1) continue;

            if (props.registredTeams[j][k].teamid == teams[i].id) {
                t = true;
                isTeamRegistred = true;
                break;
            }
          }
          if(isTeamRegistred == true) break;
        }
        if(isTeamRegistred == false) {
          teams[i]['index'] = i;
          unregistredTeams.push(teams[i])
        }
        t = false;
      }
    });
    console.log(unregistredTeams)
    setLoading(false)
  }

  const registerTeam = (event) => {
    var obj = JSON.parse(event.target.value)
    var tableId = obj.tableid
    var tableIndex = obj.tableIndex
    var team = unregistredTeams[obj.teamKey]//props.registredTeams[tableId][tableIndex].teamid
    var teamid = team.teamid ? team.teamid : team.id
    var res = [...unregistredTeams];

    res.splice(obj.teamKey, 1)

    props.addRegistredTeams(tableId, tableIndex, unregistredTeams[obj.teamKey])

    if(teamsDefaultValue[teamid]) {
      tempUnregistredTeams.forEach((v, k) => {
        if(v.teamid == teamid) {
          tempUnregistredTeams.splice(k, 1)
        }
        teamsDefaultValue[teamid] = false;
      })
    } else {
      tempRegistredTeams.push({
        teamid:  teamid,//team.teamid ? team.teamid : team.id,
        contestid: props.contest.id,
        tableid: tableId
      })
      teamsDefaultValue[teamid] = true;
    }
    setUnregistredTeams(res);
  };

  const unRegisterTeam = (event) => {
    var obj = JSON.parse(event.target.value)
    var tableId = obj.tableid
    var tableIndex = obj.tableIndex
    var team = props.registredTeams[tableId][tableIndex]
    var teamid = team.teamid ? team.teamid : team.id

    var ug = [...unregistredTeams]
    ug.push(team)
    setUnregistredTeams(ug)

    if(teamsDefaultValue[teamid]) {
      tempRegistredTeams.forEach((v, k) => {
        if(v.teamid == teamid) {
          tempRegistredTeams.splice(k, 1)
        }
        teamsDefaultValue[teamid] = false;
      })
    } else {
      tempUnregistredTeams.push({
        teamid:  team.teamid ? team.teamid : team.id,
        tableid: tableId
      })
      teamsDefaultValue[teamid] = true;
    }
    props.removeRegistredTeams(tableId, tableIndex);
  }


  useEffect(() => {
    if(!x) {
      x = true
      return
    }

    for(var member in teamsDefaultValue) delete teamsDefaultValue[member];
    unregistredTeams.splice(0, unregistredTeams.length)

    loadTeams()
    x = false
  }, [props.readyRegistredTeams]); 

  const [organizeTable, setOrganizeTable] = useState(false);

  if(loading) {
    return <h1>Loading..</h1>
  }

  const sendTables = () => {
    var data = {};
    data["add"] = tempRegistredTeams ? tempRegistredTeams : [];
    data["delete"] = tempUnregistredTeams ? tempUnregistredTeams : [];
    axios.post('/api/campeonatos/'+props.contest.id+'/tabelas/valores', data)
      .then(r => {
        console.log(r);
      });
    setTempUnregistredTeams([])
    setTempRegistredTeams([])
  }

  const closeOrganizeTableContainer = () => {
    sendTables()
    props.setOrganizeTable(false)
  }
  
  const modalStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.29)',
    },
    content: {
      maxWidth: '605px',
      margin: 'auto',
      border: 'none',
      backgroundColor: '#0d1631'
    }
  }

  lax = -1;
  return (
    <Modal isOpen={props.organizeTable}
           style={modalStyle}
           contentLabel="example model"
           onRequestClose={closeOrganizeTableContainer}>
            <h1 style={{ textAlign: 'center' }}>Organizar Tabelas</h1>
           {
             Object.keys(props.registredTeams).map((v) => {
               return <Table
                 rows={props.registredTeams[v]}
                 tableid={v}

                 unregistredTeams={unregistredTeams}
                 />;
             })
           }
   </Modal>
  );
}

export default OrganizeTableContainer;
