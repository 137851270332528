import { useState } from 'react';
import { HOST } from '../../constant/constant';

const PictureInput = (props) => {

  const [image, setImage] = useState(HOST+"/public/image/defaultshield.png")
  
  const onChange = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]))
    if(props.setImage) props.setImage(event.target.files[0]);
  }

  return (
    <div>
        <div className="creatorForm-img">
          <img src={image} />
        </div>

        <div className="creatorForm-image">
          <label for="files" className="btn">ESCOLHER IMAGEM</label>
          <input name="image" id="files" type="file" onChange={onChange}/>
        </div>
      </div>
  )
}

export default PictureInput;
