import '../Players/Players.css';


import { ReactComponent as PencilSvg } from '../UpdateGameForm/PencilSvg.svg';
import '../UpdateGameForm/UpdateGameForm.css';
import { ReactComponent as TrashSvg } from '../Players/Trash.svg';
import Modal from 'react-modal';
import SimpleButton from '../buttons/SimpleButton';
import { onFormInput } from '../../utils/FormInput.js'
import FormButton from '../buttons/FormButton';
import axios from 'axios';
import { useState, useEffect } from 'react';

const Arbitration = (props) => {
  return (
    <div className="player">
      
      <div className="plyer-head">
        <h2>{props.arbitration.name}</h2>
      </div>
      <div className="plyer-inf">
        <div className="plyer-inpt">
          <span className="pln">Arbitro central:</span>
          <span className="tln">{props.arbitration.referee}</span>
        </div>
        <div className="plyer-inpt">
          <span className="pln">Arbitro assistente:</span>
          <span className="tln">{props.arbitration.assistantReferee}</span>
        </div>
        <div className="plyer-inpt">
          <span className="pln">Arbitro assistente:</span>
          <span className="tln">{props.arbitration.assistantReferee2}</span>
        </div>
        <div className="plyer-inpt">
          <span className="pln">Quarto arbitro:</span>
          <span className="tln">{props.arbitration.thirdReferee}</span>
        </div>
      </div>
      <div className="plyer-down">
        <UpdateArbitration setArbitration={props.setArbitration}  arbitration={props.arbitration} />

        <div className="plyer-op">
          <TrashSvg style={ { color: '#cc2525'} }/>
        </div>
      
      </div>
    </div>
  );
}

const RegisterArbitration = (props) => {

  const [popup, setPopup] = useState(false)

  const closePopup = () => {
    setPopup(false)
  }
  
  const onFormSubmit = (e) => {
    var data = onFormInput(e, true) 
    
    axios.post('/api/copas/'+props.cupid+'/arbitragens', data).then(response => {
      props.addArbitration(data)
    });

    closePopup()
  }

  const popupStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.29)',
    },
    content: {
      maxWidth: '420px',
      margin: 'auto',
      border: 'none',
      background: 'rgb(9, 16, 35) none repeat scroll 0% 0%'//'rgba(0,0,0,.35)'
    }
  };

  return (
    <div className="reg">
      <SimpleButton text="Registrar equipe arbitragem" 
        style={{ width: '80%'}}
        onClick={() => setPopup(true)}
      />
      <Modal isOpen={popup}
              transparent={true}
              tranparency='50%'
              style={popupStyle}
              contentLabel="example model"
              onRequestClose={closePopup}>
        
        <h1 style={{ textAlign: 'center' }}>Registrar equipe</h1>
 
        <div className="updt-inpts" style={{ margin: 'auto' }}>
          <form onSubmit={onFormSubmit}>

            <div className="updt-inpt">
              <span>Nome da equipe:</span>
              <input name="name" type="text" placeholder="Nome" />
            </div>

            <div className="updt-inpt">
              <span>Arbitro central:</span>
              <input name="referee" type="text" placeholder="Arbitro central" />
            </div>

            <div className="updt-inpt">
              <span>Arbitros assistentes:</span>
              <input name="assistantReferee" type="text" placeholder="Arbitro assistente"  />
              <input name="assistantReferee2" type="text" placeholder="Arbitro assistente" />
            </div>

            <div className="updt-inpt">
              <span>Quarto arbitro:</span>
              <input name="thirdReferee" type="text" placeholder="Quarto arbitro" />

            </div>

          <SimpleButton dStyle={{ marginTop: '30px', textAlign: 'center' }} style={{ margin: 'auto', width: '80%', borderRadius: '2px', background: '#1a9727' }}text="Registrar" />
          </form>
       </div>
     </Modal>
    </div>
  )

}

const UpdateArbitration = (props) => {

  const [popup, setPopup] = useState(false)
  const [arbitration, setArbitration] = useState(props.arbitration);
  const onFormSubmit = (e) => {
    var data = onFormInput(e, false)
    axios.put('/api/arbitragens/'+props.arbitration.id, data)
    setPopup(false);
  }

  const updateInpt = (e) => {
    var x = props.arbitration
    x[e.target.name] = e.target.value;
    setArbitration(x)
    props.setArbitration(x)
  }

  const closePopup = () => {
    setPopup(false)
  }
  
  const popupStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.29)',
    },
    content: {
      maxWidth: '420px',
      margin: 'auto',
      border: 'none',
      background: 'rgb(9, 16, 35) none repeat scroll 0% 0%'//'rgba(0,0,0,.35)'
    }
  };

  return (
    <div className="plyer-op">
      <PencilSvg onClick={() => setPopup(true)} style={ { color: 'orange'} }/>
      <Modal isOpen={popup}
          transparent={true}
          tranparency='50%'
          style={popupStyle}
          contentLabel="example model"
          onRequestClose={closePopup}>
        
        <h1 style={{ textAlign: 'center' }}>Atualizar equipe</h1>
        
        <div className="updt-inpts" style={{ margin: 'auto' }}>
          <form onSubmit={onFormSubmit}>

            <div className="updt-inpt">
              <span>Nome da equipe:</span>
              <input onChange={updateInpt} name="name" value={arbitration.name} type="text" placeholder="Nome" />
            </div>

            <div className="updt-inpt">
              <span>Arbitro central:</span>
              <input onChange={updateInpt}  value={arbitration.referee} name="referee" type="text" placeholder="Arbitro central" />
            </div>

            <div className="updt-inpt">
              <span>Arbitros assistentes:</span>
              <input onChange={updateInpt} value={arbitration.assistantReferee} name="assistantReferee" type="text" placeholder="Arbitro assistente"  />
              <input onChange={updateInpt} value={arbitration.assistantReferee2} name="assistantReferee2" type="text" placeholder="Arbitro assistente" />
            </div>

            <div className="updt-inpt">
              <span>Quarto arbitro:</span>
              <input onChange={updateInpt} value={arbitration.thirdReferee} name="thirdReferee" type="text" placeholder="Quarto arbitro" />

            </div>

          <SimpleButton dStyle={{ marginTop: '30px', textAlign: 'center' }} style={{ margin: 'auto', width: '80%', borderRadius: '2px', background: '#1a9727' }}text="Atualizar" />
          </form>
       </div>
     </Modal>
    </div>
  )
}

const Arbitrations = (props) => {
 
  const [arbitrations, setArbitrations] = useState([]);
  const [loading, setLoading] = useState(true)

  const loadArbitrations = () => {
    axios.get('/api/copas/'+props.cupid+'/arbitragens').then(r => {
      setArbitrations(r.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    loadArbitrations()
  }, [])

  if(loading) {
    return <h1>Loading</h1>;
  }

  const addArbitration = (v) => {
    var x = [...arbitrations]
    x.push(v)
    setArbitrations(x)
    console.log('added')
  }

  const updateArbitration = (v) => {
    arbitrations.forEach((r, k) => {
      if(r.id == v.id) {
        var x = [...arbitrations]
        x[k] = v;
        setArbitrations(x)
      }
    })
  }

  return (
    <div className="a">
      <RegisterArbitration 
        cupid={props.cupid}
        addArbitration={addArbitration}
      />
      
      <div className="players">
        {
          arbitrations.map(r => 
            <Arbitration setArbitration={updateArbitration} arbitration={r} />
          )
        }
      </div>
    </div>
  );
}

export default Arbitrations;

