import './SimpleButton.css';

const SimpleButton = (props) => {

  return (
    <div style={props.dStyle} className="simple-button">
      <button style={props.style} onClick={props.onClick}>{props.text}</button>
    </div>
  );

}

export default SimpleButton;
