import SimpleButton from '../buttons/SimpleButton'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { ReactComponent as LeftArrowSvg } from '../Game/ArrowLeft.svg';
import { ReactComponent as RightArrowSvg } from '../Game/ArrowRight.svg';
import Game from '../Game/Game';
import axios from 'axios';

const Gamess = (props) => {
  
  return (
    <div className="game">
      <span>
        <Link to={"/jogos/"+props.game.id}>{
        props.teams[props.game.team1] ? 
       props.teams[props.game.team1].name : props.game.team1 } vs {
        props.teams[props.game.team2] ?
       props.teams[props.game.team2].name : props.game.team2 }</Link>
      </span>
    </div>
  )

}

const Games = (props) => {

  const elminatoryNames = {
    1: "Final",
    2: "Semifinal",
    4: "Quartas de finais",
    8: "Oitavas de finais",
    16: "Decima sexta finais"
  };

  const [loading, setLoading] = useState(true)

  const [games, setGames] = useState([]);
  
  const [eliminatoryGames, setEliminatoryGames] = useState([]);
  const [eliminatoryPharse, setEliminatoryPharse] = useState(0);
  
  const [rounds, setRounds] = useState({})
  const [roundid, setRoundid] = useState(0)

  const [openGames, setOpenGames] = useState(true)
  const [closedGames, setClosedGames] = useState(false)
  
  const loadGames = () => {
    var temp = [];
    var gmes = [...props.games]
    var eGames = [];

    for(var i = 0; i < gmes.length; i++) {
      if(gmes[i].gameType == 1) {
        eGames.push(gmes[i]);
        delete(gmes[i]);
      }
    }

    var lst = [{ id: null }]
    var tempLst = [];

    var rel = []
    var k = 0

    var i = 0;
    var x = lst.length
    while(i < x) {
      for(var j = 0; j < eGames.length; j++) {
        if(eGames[j].nextid == lst[i].id) {
          tempLst.push(eGames[j]);
          if(!rel[k]) rel[k] = [];
          rel[k].push(eGames[j]);
        }
      }
      if(i == lst.length-1) {
        k += 1
        i = 0
        lst = [...tempLst]
        x = lst.length
        tempLst = new Array();
        continue;
      }
      i+=1;
    }
    rel.reverse()
    setEliminatoryGames(rel);
    setGames( gmes );

    for(var i = 0; i < (gmes.length / 2)+4; i++) {
      temp[i] = [];
    }
    var c = props.contest.teamsAmount;
    var gamesPerRound = (c*(c-1) / 2)
    gamesPerRound = c % 2 == 0 ? gamesPerRound / (c-1) : gamesPerRound / c

    var rc = 0;
    var rdx = { 0: [] };
    var t = 0;
    for(var i = 0; i < gmes.length; i++) {
      if(t == gamesPerRound) {
        rc += 1;
        rdx[rc] = [];
        t = 0;
      }
      rdx[rc].push(gmes[i])
      t += 1;
    }
    setRounds(rdx)
    setLoading(false)
  }

  useEffect(() => {
    setOpenGames(false)
    setClosedGames(false)
    setRounds({})
    setRoundid(0)
    loadGames();
  }, [props.games]);

  const leftround = () => {
    if(roundid <= 0) {
      return
    }
    setRoundid(roundid-1)
  }

  const rightround = () => {
    if(roundid+1 >= Object.keys(rounds).length) {
      return
    }

    setRoundid(roundid+1)
  }

  const rightEliminatoryPharse = () => {
    if(eliminatoryPharse+1 >= eliminatoryGames.length) {
      return
    }
    setEliminatoryPharse(eliminatoryPharse+1)
  }
  const leftEliminatoryPharse = () => {
    if(eliminatoryPharse <= 0) {
      return
    }
    setEliminatoryPharse(eliminatoryPharse-1)
  }

  if(loading || !rounds[roundid]) {
    return null;
  }
  console.log("rounds:")
  console.log(rounds)

  const updateGames = (v) => {
    var x = [...games];
    x.forEach((r, k) => {
      if(v.id == r.id) {
        x[k] = v;
        setGames(x)
        return
      }        
    })
  }
  console.log("games:")
  console.log(games)

  return (
    <div className="games">
      { eliminatoryGames.length > 0 &&
      <div className="round-container" >
        <div className="round-menu">
          <div className="round-op">
            <LeftArrowSvg
            onClick={ leftEliminatoryPharse } />
          </div>
          <div>
            <span>{ elminatoryNames[eliminatoryGames[eliminatoryPharse].length] ? elminatoryNames[eliminatoryGames[eliminatoryPharse].length] : eliminatoryPharse+" FINAL"}</span>
          </div>
          <div className="round-op">
            <RightArrowSvg
              onClick={ rightEliminatoryPharse }
            />
          </div>
        </div>
        { eliminatoryGames[eliminatoryPharse].map((v) => {
          return (
            <Game
              cupid={props.cupid}
              teams={props.teams}
              setGame={updateGames}
              game={v} />
          )
        })
        }
      </div>
      }
      
      { rounds[roundid] != undefined && <div className="round-container" style={{ marginTop: '25px'}}>
        <div className="round-menu">
          <div className="round-op">
            <LeftArrowSvg
            onClick={leftround} />
          </div>
          <div>
            <span>RODADA {roundid+1}</span>
          </div>
          <div className="round-op">
            <RightArrowSvg
              onClick={rightround}
            />
          </div>
        </div>
        { rounds[roundid].map((v) => {
          return (
            <Game
              cupid={props.cupid}
              teams={props.teams}
              setGame={updateGames}
              game={v} />
          )
        })
        }
      </div> }


    </div>
  )
}

export default Games;
