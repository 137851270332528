import '../Page.css';

import './TeamsPage.css'

import { ReactComponent as PersonGear } from './PersonGear.svg';


import PictureInput from '../../components/PictureInput/PictureInput'; 
import Toggle from '../../components/Toggle/Toggle'; 
import SimpleButton from '../../components/buttons/SimpleButton';
import BorderButton from '../../components/buttons/BorderButton';

import RegisterTeamMenu from '../../components/RegisterTeamMenu/RegisterTeamMenu';
import Contests from '../../components/Contests/Contests';
import Teams from '../../components/Teams/Teams';

import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import React from "react";
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import Modal from 'react-modal';

import axios from 'axios';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

var did = false

const TeamsPage = (props) => {
  
  const { cupid } = useParams();
  let query = useQuery()

  const [contestid, setContestid] = useState(0);
  const [teams, setTeams] = useState([]);
  
  const [contestTeams, setContestTeams] = useState({});

  const [contests, setContests] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const location = useLocation()
  const tempContests = undefined;
  
  if(location.state) {
    const { tempContests } = location.state
  }
  const loadTeams = (contestId) => {
  }

  const loadContests = () => {
    if(!tempContests) {
      axios.get('/api/copas/'+cupid+'/campeonatos').then(response => {
        setContests(response.data);

        var cid = query.get('contestid')
        var id = cid ? cid : response.data[0].id
  
        setContestid(id)

        axios.get('/api/campeonatos/'+id+'/times').then(response => {
          var v = {...contestTeams}
          v[id] = response.data;
          setContestTeams(v);
          setLoading(false)
        });
      });
    } else {
      console.log("loading teams...")
      loadTeams();
    }
  }


  const uploadContestid = async (consid) => {
    setContestid(consid)
    if(!contestTeams[consid]) {
      var x = await axios.get('/api/campeonatos/'+consid+'/times');
      var v = {...contestTeams};
      v[consid] = x.data;
      setContestTeams(v);
      console.log("setting contestTeams to:")
      console.log(v)
    }
  }

  useEffect(() => {
    loadContests();
  }, [])
  console.log('contestTeams:')
  console.log(contestTeams)

  if(loading) {
    return <h1>Loading...</h1>
  }

  console.log("contestid: "+contestid)

  if(!contestTeams[contestid]) {
    console.log(contests)
    console.log(contestTeams)
    return <h1>Loading</h1>
  }

  return (
    <div className="psimple">
      <div className="psimple-container">
        <Contests 
          contestid={contestid}
          cupid={cupid}
          setContestid={uploadContestid}
          contests={contests}
        /> 

        <CoachPerms contestid={contestid}/>

       { contestTeams[contestid].length <= 0 &&
          <RegisterTeamMenu 
            contests={contests}
            contestid={contestid}
            teamslen={teams.length}
            cupid={cupid}
          />
       }

       {
        contestTeams[contestid].length >= 1 &&
          <Teams 
            cupid={cupid}
            contestid={contestid}
            contests={contests}
            teams={contestTeams[contestid]}
          />
       }
     </div>

    </div>
  );
}

const CoachPerms = (props) => {
  const [popup, setPopup] = useState(false)
  const [contestConfig, setContestConfig] = useState({})

  const loadContestConfig = () => {
    axios.get('/api/campeonatos/'+props.contestid+'/times/config').then(r => {
      setContestConfig(r.data)
    });
  }
  const closeContainer = () => {
    setPopup(false);
  }

  const onChangeC = (e) => {
    var x = {...contestConfig};
    x[e.target.name] = !x[e.target.name];
    setContestConfig(x)
  }

  const onChange = (e, v = undefined) => {
    var x = {...contestConfig};

    if(!v) {
      x[e.target.name] = e.target.value;
    } else {

      if(x[e.target.name] == v) {
        if(v == 2) {
          x[e.target.name] = 1;
        } else if(v == 1) {
          x[e.target.name] = 0;
        } else {
          x[e.target.name] = v;
        }
      } else {
        if(x[e.target.name] == 2) {
          x[e.target.name] = 0;
        } else {
          x[e.target.name] = v;
        }
      }
    }
    setContestConfig(x)
  }
  
  const saveContestConfig = () => {
    axios.put('/api/campeonatos/'+props.contestid+'/times/config', contestConfig);
    closeContainer()
  }

  useEffect(() => {
    loadContestConfig()
  }, []);
  
  const popupStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.29)',
    },
    content: {
      maxWidth: '420px',
      margin: 'auto',
      border: 'none',
      background: 'rgb(9, 16, 35) none repeat scroll 0% 0%'//'rgba(0,0,0,.35)'
    }
  };

  return (
    <div>
      <Modal isOpen={popup}
              transparent={true}
              tranparency='50%'
              style={popupStyle}
              contentLabel="example model"
              onRequestClose={closeContainer}>


          <div className="updt-inpts" style={{ margin: 'auto'}}>
            <h1>Geral:</h1>
            <div className="updt-inpt">
              <span>Maximo jogadores por time:</span>
              <input onChange={onChange} name="maxPlayersCount" type="number" value={contestConfig.maxPlayersCount} />
            </div>

            <h1>Permissoes dos tecnicos</h1>
            

            <div className="updt-inpt flx">

              <div>
                <Toggle q={true} onChange={onChangeC} checked={contestConfig.insertable} name={"insertable"} />
              </div>
              <div className="sfv">
                <span>Permitir inscricao de jogadores:</span>
              </div>
            </div>
            

            <div className="updt-inpt flx">
              <div>
                <Toggle q={true} onChange={onChangeC} checked={contestConfig.updatable} name={"updatable"} />
              </div>
              <div className="sfv">
                <span>Permitir edicao jogadores:</span>
              </div>
            </div>
            
            <div className="updt-inpt flx">
              <div>
                <Toggle q={true} onChange={onChangeC} checked={contestConfig.deletable} name={"deletable"} />
              </div>

              <div className="sfv">
                <span>Permitir remocao de jogadores:</span>
              </div>
            </div>

            <h1>Ficha de inscricao dos jogadores:</h1>
            
            <div className="updt-lst">
              <tbody>
                <tr>
                  <td className="table-team-v">Informacao</td>
                  <td>Pedir</td>
                  <td>Obrigatorio</td>
                </tr>

                <tr>
                  <td className="table-team-v">Foto</td>
                  <td> <Toggle checked={ contestConfig.picture > 0 } name={"picture"} onChange={(e) => onChange(e, 1)} /> </td>
                  <td> <Toggle name={"picture"} onChange={(e) => onChange(e, 2)} checked={ contestConfig.picture == 2 } /> </td>
                </tr>
                
                <tr>
                  <td className="table-team-v">CPF</td>
                  <td> <Toggle name={"cpf"} checked={ contestConfig.cpf > 0 } onChange={(e) => onChange(e, 1)} /> </td>
                  <td> <Toggle name={"cpf"} onChange={(e) => onChange(e, 2)} checked={ contestConfig.cpf == 2 } /> </td>
                </tr>
                <tr>
                  <td className="table-team-v">RG:</td>
                  <td> <Toggle name={"rg"} checked={ contestConfig.rg > 0 } onChange={(e) => onChange(e, 1)} /> </td>
                  <td> <Toggle name={"rg"} onChange={(e) => onChange(e, 2)} checked={ contestConfig.rg == 2 }/> </td>
                </tr>
                <tr>
                  <td className="table-team-v">Cert. nascimento</td>
                  <td> <Toggle name={"certNasc"} checked={ contestConfig.certNasc > 0 } onChange={(e) => onChange(e, 1)} /> </td>
                  <td> <Toggle name={"certNasc"} checked={ contestConfig.certNasc == 2 } onChange={(e) => onChange(e, 2)} /> </td>
                </tr>

              </tbody>
            </div>

            <div style={{ marginTop: '15px' }}>
              <SimpleButton onClick={saveContestConfig} style={{width: '100%'}} text="Salvar"/>
            </div>

          </div>
          
        </Modal>
      <div className="cnts">
        <div className="cnts-i">
          <BorderButton onClick={() => setPopup(true)}>
            <PersonGear />
            <span>PERMISSOES DOS TECNICOS</span>
          </BorderButton>
        </div>
      </div>
    </div>

  )
}

export default TeamsPage;
