import axios from 'axios';
import '../TeamPage/TeamPage.css';

import SimpleButton from '../../components/buttons/SimpleButton';

import Players from '../../components/Players/Players';
import { useState, useEffect } from 'react';
import { useLocation ,useParams } from 'react-router-dom';
import RegisterPlayer from '../../components/RegisterPlayer/RegisterPlayer';

const SubscribePlayerPage = (props) => {

  const location = useLocation();

  const { teamid, contestid } = useParams();
  const [ team, setTeam] = useState({});
  const [ players, setPlayers ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ registerPlayerContainer, setRegisterPlayerContainer] = useState(false);
  const [contestConfig, setContestConfig] = useState({})

  const loadContestConfig = () => {
    axios.get('/api/campeonatos/'+contestid+'/times/config').then(r => {
      setContestConfig(r.data)
    });
  }

  const loadPlayers = () => {
    axios.get('/api/campeonatos/'+contestid+'/times/'+teamid+'/jogadores').then(response => {
      setPlayers(response.data)
    })
  }

  const loadTeam = () => {
    axios.get('/api/times/'+teamid).then(r => {
      setTeam(r.data[0])
    })
  }

  const addPlayer = (data) => {
    var cp = [...players]
    cp.push(data)
    setPlayers(cp)
  }

  const deletePlayer = (id) => {
    axios.delete('/api/campeonatos/'+contestid+'/jogadores/'+players[id].playerid)
      .then(response => {
        var cp = [...players]
        cp.splice(id, 1);
        setPlayers(cp)
      });
  }

  useEffect(() => {
    loadContestConfig()
    loadPlayers() 
    loadTeam()
    setLoading(false)
  }, []);

  if(loading || team.length <= 0) {
    return <h1>Loading...</h1>;
  }

  console.log("team:")
  console.log(team)
  console.log("contestConfig:")
  console.log(contestConfig)
  return (
    <div className="gpage" style={{ width: '100%', marginTop: '25px'}} >
      <div style={{ width: '100%' }}>
        <div className="tm">
          <div className="tm-img">
            <img src={'/public/image/'+team.picture} />
          </div>
          <span>{team.name}</span>
        </div>
        
      </div>
      
      <h1 style={{ textAlign: 'center', marginTop: '15px'}}>{players.length > 0 
        ? players.length+" jogadore(s)" 
        : "Nenhum jogador registrado" }</h1>


      { contestConfig.insertable == true &&
      <RegisterPlayer
        teamid={teamid}
        containerAction={setRegisterPlayerContainer}
        playersAmount={players.length}
        contestid={contestid}
        registerPlayerContainer={registerPlayerContainer}
        addPlayer={addPlayer}
      /> }


      <Players  
        players={players}
        deletePlayer={deletePlayer}
        canDelete={contestConfig.deletable}
        canUpdate={contestConfig.updatable}
        contestid={contestid}
      />
    </div>
  );
}

export default SubscribePlayerPage;

