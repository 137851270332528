import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Games from '../Games/Games';
import RiffleGames from '../RiffleGames/RiffleGames';

const GamesContainer = (props) => {

  return (
    <div className="games-container">

      {
        props.contest.state == 0 &&
          <RiffleGames
            contest={props.contest}
            setContest={props.setContest}
            setGames={props.setGames}
            registredTeams={props.registredTeams}
          />
      }

      <Games 
        contest={props.contest}
        cupid={props.cupid}
        registredTeams={props.registredTeams}
        games={props.games}
        teams={props.teams}
        setGames={props.setGames}
      />
    </div>
  );

}

export default GamesContainer;
