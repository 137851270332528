import { Link } from 'react-router-dom';
import { HOST } from '../../constant/constant';
import { useState } from 'react';
import { ReactComponent as HomeSvg } from './Home.svg';
import { ReactComponent as TableSvg } from './Table.svg';
import { ReactComponent as TeamsSvg } from './Teams.svg';
import { ReactComponent as AuthSvg } from './Auth.svg';
import './LeftMenu.css';

const LeftMenu = (props) => {

  const [selected, setSelected] = useState(2);

  const selectedStyle = { background: '#0b1020' }

  return (
    <div className="left-menu">
      <div className="cup-profile">
        <img src={HOST+"/public/image/"+props.cup.picture}/>
        <span style={{ marginTop: '15px'}}>{props.cup.name}</span>
      </div>
      <div className="menu-options">
            
        <Link to="/">
          <div className="menu-option"
            onClick={ () => setSelected(0) }
            style={ selected == 0 ? selectedStyle : null }
            >


            <div>
              <span>
                <div>
                  <HomeSvg />
                </div>
              </span>
            </div>
            <div>
              <span>Inicio</span>
            </div>

          </div>
        </Link>


        <Link to={"/copas/"+props.cupid}>
          <div className="menu-option"
            onClick={ () => setSelected(2) }
            style={ selected == 2 ? selectedStyle : null }
          >
            <div>
              <span>
                <div>
                  <TableSvg />
                </div>
              </span>
            </div>
            <div>
              <span>Tabela</span>
            </div>

          </div>
        </Link>
        
        <Link to={"/copas/"+props.cupid+"/times"}>
          <div className="menu-option"
            onClick={ () => setSelected(3) }
            style={ selected == 3 ? selectedStyle : null }
          >
            <div>
              <span>
                <div>
                  <TeamsSvg />
                </div>
              </span>
            </div>

            <div>
              <span>Times</span>
            </div>
          </div>
        </Link>
        
        <Link to={"/copas/"+props.cupid+"/arbitragens"}>
          <div className="menu-option"
            onClick={ () => setSelected(1) }
            style={ selected == 1 ? selectedStyle : null }
          >
            <div>
              <span>
                <div>
                  <AuthSvg />
                </div>
              </span>
            </div>

            <div>
              <span>Arbitragens</span>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default LeftMenu;
