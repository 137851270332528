import './FormButton.css';

const FormButtonC = (props) => {

  return (
    <div className="form-button">
      <button style={props.style} 
      onClick={props.onClick}>{props.children}</button>
    </div>
  );

}

export default FormButtonC;
