import './Players.css'
import { HOST } from '../../constant/constant';

import { ReactComponent as TrashSvg } from './Trash.svg';
import UpdatePlayerForm from './UpdatePlayerForm/UpdatePlayerForm';
import SimpleButton from '../buttons/SimpleButton';

const Players = (props) => {

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
  }

  return (
    <div className="players">
      {
        props.players.map((v, k) => {
          return (
            <div className="player">
              <div className="player-header">
                
                <div className="player-picture">

                <img src={ v.picture ? HOST+"/public/image/"+v.picture
                  : HOST+"public/image/16772525961677252596"} />
                </div>
              
                <div className="player-header-inf">
                  <span>{v.name}</span>
                  {
                    v.birth != null ? 
                      <span>{ getAge(v.birth) } anos</span> : null
                  }
                </div>

              </div>
              <div className="player-down">

                <div className="player-down-i">
                  <span>Data nascimento: </span>
                  <span className="blue">{v.birth}</span>
                </div>

                  { v.cpf && (
                    <div className="player-down-i">
                      <span>Cpf: </span>
                      <span className="blue">{v.cpf}</span>
                    </div> )}
                  { v.cg && (
                    <div className="player-down-i">
                      <span>RG: </span>
                      <span className="blue">{v.rg}</span>
                    </div> )}
                
                <div className="player-down-i">
                  <span>Inscrito em: { v.registred_at }</span>
                </div>
              </div>
              <div className="player-btns">
                {
                  props.canUpdate == true && <UpdatePlayerForm player={v} contestid={props.contestid}/>
                }
                {
                
                props.canDelete == true && <TrashSvg
                  onClick={() => props.deletePlayer(k)}
                />
                
                }


              </div>
            </div>
          )
        })
      }
    </div>
  );
}

export default Players;
