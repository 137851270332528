import LeftMenu from '../components/menu/LeftMenu';
import './Home.css';
import axios from 'axios';
import SimpleButton from '../components/buttons/SimpleButton';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cup from '../components/Cup/Cup';

const HomePage = (props) => {

  const [cups, setCups] = useState([ {name: "something", state: 0, id: 2} ]);
  const [cupState, setCupState] = useState(-1)

  const loadUserCups = async () => {
    await axios.get('/api/usuarios/'+props.user.id+'/copas')
      .then(response => {
        setCups(response.data)
      });
  }

  useEffect(() => {
    loadUserCups()
  }, [])

  return (
    <div className="home">
      <div className="contests">
          <h2>Copas</h2>
          <h4> All Laracasts series are categorized into various topics. This should provide you with an alternate way to decide what to learn next, whether it be a particular framework, language, or tool. </h4>
        </div>
        <div style={{ textAlign: 'center', marginTop: '25px' }}>
          <Link to="/copas/novo"> 
            
            <SimpleButton 
              style={{
                width: '41%',
                fontWeight: 400,
                fontSize: '1rem',
                borderRadius: '3px'
              }}
              onClick={ () => {}}
              text="Criar nova copa"/>
            
            </Link>
        </div>
        
        <div className="cups-container">
          {cups.map((cup) => {
            
            return cupState == cup.state ?
                  <Cup 
                    cup={cup}
                    setCupid={props.setCupid}
                    setCup={props.setCup}
                   /> 
                    : 

                    (cupState == -1 ? 
                    
                    <Cup 
                      cup={cup}
                      setCup={props.setCup}
                      setCupid={props.setCupid}
                      cupState={cupState}/>
                      : null)
          })}
        
        </div>
    </div>
  );

}

export default HomePage;
