import '../Page.css'

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SimpleButton from '../../components/buttons/SimpleButton'
import Contest from '../../components/Contest/Contest';
import Contests from '../../components/Contests/Contests';
import Games from '../../components/Games/Games';
import RaffleGamesButton from '../../components/buttons/RaffleGamesButton';
import React from "react";
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";

import axios from 'axios';
  

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

//var contest = {}
const CupPage = (props) => {

  let query = useQuery();

  const [contestid, setContestid] = useState(-1);
  const [loading, setLoading] = useState(true)
  const [contests, setContests] = useState([])
  const [contest, setContest] = useState({})

  const { cupid } = useParams();
  
  const updateContest = async () => {
    console.log("update contest")
    await axios.get("/api/copas/"+cupid+"/campeonatos").then(response => {
      setContests(response.data)
      
      var cid = query.get('contestid')
      console.log('setting cid: ', cid)
      console.log('contestid: ', contestid)
      !cid ? setContestid(response.data[0].id) : setContestid(cid); // : null;
      console.log("response")
      console.log(response.data)
      console.log(response.data[0].id)
    });
  }


  useEffect(() => {
    updateContest()
    console.log(contests)
    console.log(contestid)
  }, []);

  useEffect(() => {
    contests.map(v => { 
      if(v.id == contestid) {
        console.log("setting ", v)
        setContest(v)
        //contest = v;
      }
      //history.push('/copas/'+cupid+'?contestid='+v.id);
      setLoading(false)
    })
  }, [contestid]);
 
  const loadContest = (id) => {
    //contest = contests[id]
    setContest(contests[id])
    contests.forEach((v, k) => {
      if(v.id == id) {
        //contest = contests[k]
        setContest(contests[k])
        return
      }
    })
    console.log("CONTEST NOW")
    console.log(contest)
    
    setContestid(id)// : null;
  }

  const updateContestContent = (contst) => {
    setContest(contst)
    var x = [...contests]
    contests.forEach((v,k) => {
      if(v.id == contst.id) {
        x[k] = contst;
        setContests(x)
        return
      }
    })
  }


  if(loading) {
    return <h1>Loading...</h1>;
  }

  if(contestid == -1 || contests.length == 0) {
    return <h1>Loading...</h1>;
  }

  if(contest.id != contestid) {
    return <h1>Loading...</h1>
  }

  console.log(contestid)
  console.log(contests)

  return (
    <div className="psimple">
      <div className="psimple-container">
        <div className="banner">
          <img src="https://img.freepik.com/premium-vector/modern-blue-abstract-background-banner-modern-light-blue-abstract-banner-design-background_181182-28914.jpg"/>
        </div>
        { 
          <Contests 
            contestid={contestid}
            cupid={cupid}
            setContestid={loadContest}
            contests={contests}
          /> 
        }

        { 
          <Contest 
            contest={contest}
            setContest={setContest}
            contests={contests}
            setContest={updateContestContent}
            cupid={cupid}
            contestid={contestid}
            games={props.games}
            setGames={props.setGames}
          /> 
        }
      </div>
    </div>
  );
}
export default CupPage;
