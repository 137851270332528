import { ReactComponent as PencilSvg } from './PencilSvg.svg';
import SimpleButton from '../buttons/SimpleButton';
import { useState, useEffect } from 'react';
import Modal from 'react-modal';

import axios from 'axios';
import './UpdateGameForm.css';

const UpdateGameForm = (props) => {

  const [popup, setPopup] = useState(false);
  const [game, setGame] = useState(props.game)

  const sendUpdatedGame = () => {
    axios.put('/api/jogos/'+props.game.id, game)
    setPopup(false)

  }
  const updateGame = (e) => {
    var x = props.game;
    x[e.target.name] = e.target.value;
    props.setGame(x)
    setGame(x)
  }

  const modalStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.29)',
    },
    content: {
      maxWidth: '420px',
      margin: 'auto',
      border: 'none',
      background: 'rgb(9, 16, 35) none repeat scroll 0% 0%'//'rgba(0,0,0,.35)'
    }
  };

  return (
    <div className="upc">

      <div className="up-gme" onClick={() => setPopup(true)}>
        <div className="up-gm">
          <PencilSvg />
        </div>
        {
          game.whenStart && 
          <div className="up-gm">
            <span>{game.whenStart}</span>
          </div> 
        }
        { game.pitch &&
        <div className="up-gm">
          <span style={{ color: '#a2a2a2', fontWeight: 'normal', fontFamily: 'sans', fontSize: '0.8rem'}}>{'Campo - '+game.pitch}</span>
        </div>
    }
      </div>
      <Modal isOpen={popup} 
              style={modalStyle}
              onRequestClose={() => setPopup(!popup)}>
        <h1 style={{ textAlign: 'center' }}>Editar jogo</h1>
        <div className="updt-inpts">
          <div className="updt-inpt">
            <span>Campo</span>
            <input name="pitch" placeholder="campo" onChange={updateGame} value={props.game.pitch}/>
          </div>
          <div className="updt-inpt">
            <span>Link transmissao ao vivo</span>
            <input name="link" placeholder="link" onChange={updateGame} value={props.game.link}/>
          </div>
          <div className="updt-inpts-flex">
            <div className="updt-inpt">
              <span>Data e horario</span>
              <input name="whenStart" type="datetime-local" onChange={updateGame} value={props.game.whenStart}/>
            </div>
          </div>
        </div>
       <div className="updt-btn"> 
          <SimpleButton onClick={sendUpdatedGame} dStyle={{ marginTop: '30px', textAlign: 'center' }} style={{ margin: 'auto', width: '80%', borderRadius: '2px', background: '#1a9727' }}text="Editar" />
        </div>
      </Modal>

    </div>
  )
}

export default UpdateGameForm;
