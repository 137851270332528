import './Game.css'
import UpdateGameForm from '../UpdateGameForm/UpdateGameForm';
import { Link } from 'react-router-dom';
import { HOST } from '../../constant/constant';

const Game = (props) => {

  console.log("game:")
  console.log(props.game)
  if(props.game == undefined) {
    return (
      <div className="game">
        <div className="game-info"> 
          <div class="game-team">
            <img src={HOST+"/public/image/"+ (team1 ? team1.picture : "defaultshield.png") } />
            <span>{team1 ? team1.name : ""}</span>
          </div>
     
          <div className="vs">
            <span>VS</span>
          </div>
          <div class="game-team">
            <img src={HOST+"/public/image/"+ (team2 ? team2.picture : "defaultshield.png") } />
            <span>{team2 ? team2.name : ""}</span>
          </div>
        
        </div>
      </div>
    )
    
  }

  const team1 = props.teams[props.game.team1]
  const team2 = props.teams[props.game.team2]

  const state = props.game.state
  if(!team1 || !team2) {
    return (
      <div className="game" style={ state != 0 ? {marginTop: '25px'} : {} }>
        <div className="game-info" style={{ paddingTop: '27px'}}> 
          <div class="game-team">
            <img src={HOST+"/public/image/"+ (team1 ? team1.picture : "defaultshield.png") } />
            <span>{team1 ? team1.name : ""}</span>
          </div>
     
          <div className="vs">
            <span>VS</span>
          </div>
          <div class="game-team">
            <img src={HOST+"/public/image/"+ (team2 ? team2.picture : "defaultshield.png") } />
            <span>{team2 ? team2.name : ""}</span>
          </div>
        
        </div>
      </div>
    )
  }
  return (
  <div className="tp">
    { 
      state == 0 && <UpdateGameForm taam1={team1} team2={team2} setGame={props.setGame} game={props.game}/>
    }
    <Link to={"/copas/"+props.cupid+"/jogos/"+props.game.id}>

      <div className="game" style={ state != 0 ? {marginTop: '25px'} : {} }>

        <div className="game-info" style={ props.game.state > 0 ? { paddingTop: '27px' } : null}> 
          <div class="game-team">
            <img src={HOST+"/public/image/"+team1.picture} />
            <span>{team1.name}</span>
          </div>

          <div className="game-info-txt">
              {state > 0 && (
                <div className="left-score">
                  <span>{props.game.team1goals}</span>
                </div>
              )
              }
       
            <div className="vs">
              <span>X</span>
            </div>
              {props.game.state > 0 && (
                <div className="right-score">
                  <span>{props.game.team2goals}</span>
                </div>
              )
            }
         </div>


          <div class="game-team">
            <img src={HOST+"/public/image/"+team2.picture} />
            <span>{team2.name}</span>
          </div>
        
        </div>
      </div>
    </Link>
    </div>
  );

}

export default Game;
