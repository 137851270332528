import './CreatorForm.css';
import PictureInput from '../../components/PictureInput/PictureInput'; 
import { onFormInput } from '../../utils/FormInput.js'
import FormButton from '../buttons/FormButton';
import ContestCreatorInputs from '../../components/forms/ContestCreatorInputs';
import ContestCreatorForm from './ContestCreatorForm'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react';
import axios from 'axios';

const CreatorForm = (props) => {

  const [selectedFile, setSelectedFile] = useState(0);
  const [formState, setFormState] = useState(false);
  const [error, setError] = useState("")

  const [picture, setPicture] = useState("");

  const [cup, setCup] = useState({});
  let navigate = useNavigate();

  const onFormSubmit = async (event) => { 

    var data = onFormInput(event, true) 
    if(!formState) {
      if(picture == "") {
        setError("A foto é nescessaria paracriar a copa")
        return
      }
      setCup(data)
      setFormState(true)
      return
    }
    console.log("dating...")
    console.log(data)

    var x = {...cup}
    x['contests'] = [];
    x['contests'].push(data)

    await axios.post('/api/copas', x).then(async response => {
      console.log(response.data.id)
      const fileData = new FormData();
      fileData.append('picture', picture);
  
     await axios.post('/api/copas/'+response.data.id+'/image', fileData, resp => {
        console.log(response);
      })
      x['picture'] = response.data.picture;
      x['id'] = response.data.id;
      props.setCup(x)
      props.setCupid(x['id'])
      return navigate('/copas/'+response.data.id)
    })
  };

  const onChange = (event) => {
    setSelectedFile(event.target.files[0])
  }

  return (
    <div>
      <div className="updt-cntr" style={ formState ? {display: 'none'} : {display: 'block', paddingTop: 0} }>
        <div className="creatorForm-title">
          <h1>{props.title}</h1>
        </div>

        <PictureInput 
          setImage={setPicture}
          required={true}
        />
        { error != "" && <div className="error"><span>{error}</span></div> }


        { !formState && <form style={ formState ? {display: 'none'} : {display: 'block', paddingTop: 0} } onSubmit={onFormSubmit}>
          <div className="updt-cntr">
            <div className="creatorForm-input">
              <input name="name" type="text" placeholder="Nome" required/>
            </div>
            
            <div className="creatorForm-inputs-flex" style={{ maxWidth: '94%'}}>
              <div className="creatorForm-input">
                <input name="state" type="text" placeholder="Estado" required/>
              </div>

              <div className="creatorForm-input">
                <input name="city" type="text" placeholder="Cidade" required/>
              </div>
            </div>
            <div className="creatorForm-input">
              <input name="date" type="date" placeholder="Cidade"/>
            </div>
          </div>    






          <FormButton text="CRIAR COPA" />
        </form>
        }
      </div>



        <div className="add-team" style={{ width: '454px' }}>
          <div className="creatorForm">

           <div className="creatorForm-title">
              <h1 style={{ textAlign: 'left', marginLeft: '17px', fontSize: '1.5rem', marginTop: '6px'}}>Criar categoria</h1>
           </div>
            <form onSubmit={onFormSubmit} style={{ marginTop: '-30px' }}>
              <ContestCreatorInputs />
              <FormButton text="CRIAR CAMPEONATO"/>
            </form>
          </div>
        </div>
    </div>
  );
}

export default CreatorForm;
