import { HOST } from '../../constant/constant';
import { Link } from 'react-router-dom'
import SimpleButton from '../buttons/SimpleButton';

const Teams = (props) => {
  return (
    <div className="teams-p" style={{ display: 'block'}}>

      <div className="teams-btns">
          <Link to={"/copas/"+props.cupid+"/times/criar"} state={{ contests: props.contests , contestid: props.contestid }}>
            <SimpleButton 

            style={{ background: '#16bd27', width: '100%', padding: '10px', borderRadius: '3px'}} 

            text="CRIAR TIME" />
          </Link>
        
          <Link to={"/campeonatos/"+props.contestid+"/times/importar"}>
            <SimpleButton 

            style={{ background: 'rgb(20, 55, 242)', width: '100%', padding: '10px', borderRadius: '3px'}} 
            text="IMPORTAR TIME" />
          </Link>

      </div>
       <h1 style={{ textAlign: 'center', marginTop: '15px'}}>{props.teams.length} Times</h1>
      <div className="teams">
      {
        props.teams.map(v => {
          return (

            <Link to={"/campeonatos/"+props.contestid+"/times/"+v.id}>
              <div className="team">
                <div class="team-image">
                  <img src={HOST+"/public/image/"+v.picture} />
                </div>

                <div class="team-title">
                  <span>{v.name}</span> 
                </div>
              </div>
            </Link>
          );
        })
      }
      </div>
    </div>
  );
}

export default Teams;
