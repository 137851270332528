import { useState, useEffect } from 'react';
import { onFormInput } from '../../utils/FormInput.js'
import './CreatorForm.css';

import SimpleButton from '../buttons/SimpleButton'
import FormButton from '../buttons/FormButton'
import './AuthForm.css';

import { useNavigate } from 'react-router-dom'

import axios from 'axios';

const AuthForm = (props) => {

  const [login, setLogin] = useState(true)
  const [error, setError] = useState("")
  let navigate = useNavigate();

  const onFormSubmit = async (event) => {
    var data = onFormInput(event)

    await axios.get('/sanctum/csrf-cookie').then(async response => {
      try {
        var x = await axios.post('/api/login', data);
        var res = await axios.get('/api/user');
        props.setUser(res.data)
        console.log("WORKED")
        console.log(res.data)
        return navigate('/')
        setError("")
      } catch(e) {
        setError("Não foi possivel efetuar o login")
        console.log(e);
      }
    });
  };

  const onRegisterSubmit = async (event) => {
    var data = onFormInput(event, true)
    await axios.post('/api/registrar', data)
    setLogin(false)
  };

  return (
    <div className="auth-container">
      
      <div className="auth-login-container" style={{ display: login ? 'block' : 'none'}}>
        <h1>Entrar</h1>
        <form onSubmit={onFormSubmit}>
          <div className="creatorForm-input">
            <span>Email</span>
            <input name="email" type="email" placeholder="email" required />
          </div>


          <div className="creatorForm-input margin">
            <span>Senha</span>
            <input name="password" type="password"  placeholder="Senha" required />
          </div>
          <div className="error">
            { error != "" && <span style={{ textAlign: 'center', fontWeight: '400', color: 'red'}}>{error}</span>}
          </div>

          <FormButton text="ENTRAR"/>
        </form>
        <div style={{ textAlign: "center" }}>

          <SimpleButton
            text="REGISTRAR"
            onClick={ () => { setError(""); setLogin(false) }}
          />
        </div>
      </div>

      <div className="auth-register-container" style={{ display: !login ? 'block' : 'none' }}>
         <h1>Registrar</h1>
        <form onSubmit={onRegisterSubmit}>

          <div className="creatorForm-input">
           <span>Username</span>
           <input name="name" type="text" placeholder="username" required />
         </div>
          <div className="creatorForm-input">
           <span>Email</span>
           <input name="email" type="email" placeholder="email" required />
         </div>
          <div className="creatorForm-input">
           <span>Senha</span>
           <input name="password" type="password" placeholder="senha" required />
         </div>
          <FormButton
            text="REGISTRAR"/>
        </form>

        <span>{error}</span>

        <div style={{ textAlign: 'center' }}>
          <SimpleButton
            text="LOGIN"
            onClick={ () => { setLogin(true) }}
          />
        </div>
      </div>
    </div>
  );
}

export default AuthForm;
