
import { HOST } from '../../constant/constant';
import './CreateTeamPage.css';
import '../../components/forms/CreatorForm.css';

import { onFormInput } from '../../utils/FormInput.js'
import { useState } from 'react';
import { redirect, useParams, useLocation } from 'react-router-dom'

import SimpleButton from '../../components/buttons/SimpleButton';

import { useNavigate } from 'react-router-dom'
import axios from 'axios';

var id = false;
const CreateTeamPage = (props) => {

  const [selectedFile, setSelectedFile] = useState(0);
  const location = useLocation()
  const [image, setImage] = useState(HOST+"/public/image/defaultshield.png")
  const { contests, contestid } = location.state
  const { cupid } = useParams();
  let navigate = useNavigate();

  const [contestso, setContestso]= useState(new Array(contests.length));
  if(!id) {
    contestso.fill(false)
    id = true;
  }
  const onFormSubmit = async (event) => { 
    var data = onFormInput(event, false) 
    data['contests'] = [];
    contests.forEach((v, k) => {
      if(contestso[k]) {
        data['contests'].push(v.id);
      }
    });
    
    await axios.post('/api/times', data).then(response => {
      const fileData = new FormData();
      fileData.append('image', selectedFile);
      axios.post('/api/times/'+response.data.id+'/imagem', fileData, response => {

        //return redirect('/copas/'+cupid+'/times')
      })
    })


    return navigate('/copas/'+cupid+'/times?contestid='+contestid);
  };

  const some = (v) => {
    var t = [...contestso]
    console.log(t[v])
    t[v] = !t[v];
    setContestso(t)
  }

  const onChange = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]))
    setSelectedFile(event.target.files[0])
  }
    console.log(contestso)

  return (
  <div className="gpage">
    <div className="add-team">
      <div className="creatorForm">

        <div className="creatorForm-img">
          <img src={image} />
        </div>

        <div className="creatorForm-image">
          <label for="files" className="btn">ESCOLHER IMAGEM</label>
          <input name="image" id="files" type="file" onChange={onChange} required/>
        </div>

        <form onSubmit={onFormSubmit}>

          <div className="creatorForm-input">
            <span>Dados do time:</span>
            <input name="name" type="text" placeholder="Nome" required/>
          </div>
          <div className="creatorForm-cat">
            <span>Participara de quais categorias ?</span>
          </div>
          <div className="btns">
          {
            contests.map((v, k) => {
              return ( 
                <span 
                  onClick={() => some(k)} 
                  style={{backgroundColor: contestso[k] ? '#0d1320' : '#151f32'}} 
                  className="s-btn">{v.name}
                </span>
              )
            })
          }
          </div>
           
          <div style={{ marginTop: '25px', textAlign: 'center'}}>
            <SimpleButton text="CRIAR TIME" />
          </div>
        </form>
      </div>
    </div>
  </div>
  );

}
export default CreateTeamPage;
