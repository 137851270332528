import Modal from '../Modal';
import ContestConfig from '../ContestConfig/ContestConfig';
import GruposMataContest from '../GruposMataContest/GruposMataContest';
import RiffleGames from '../RiffleGames/RiffleGames';
import GamesContainer from '../GamesContainer/GamesContainer';
import Game from '../Game/Game';
import SimpleButton from '../buttons/SimpleButton';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Games from '../Games/Games';
import './Contest.css';

const WinnerWarring = (props) => {
  const team = props.team

  return (
    <div className="winnerWarring">
      <h1>Campeonato Finalizado</h1>
      <div className="winnerWarring-img">
        <img src={"/public/image/"+team.picture}/>
      </div>
      <h1>{props.team.name}</h1>
    </div>
  )
}

var x = false;
const Contest = (props) => {

  const [table, setTable] = useState({});
  const [registredTeams, setRegistredTeams] = useState({});
  const [readyRegistredTeams, setReadyRegistredTeams] = useState(false)
  
  const [teams, setTeams] = useState({})
  const [games, setGames] = useState([])

  const [loading, setLoading] = useState(true)

  const loadGames = () => {
    axios.get('/api/campeonatos/'+props.contestid+'/jogos').then(response => {
      console.log(response.data.length)
      if(response.data.length == 0) {
        var c = props.contest.teamsAmount;
        var gamesPerRound = (c*(c-1) / 2)
        gamesPerRound = c % 2 == 0 ? gamesPerRound / (c-1) : gamesPerRound / c
        var gmes = []
        for(var i = 0; i < gamesPerRound; i++) {
          gmes.push({})
          //gmes.push({ id: null, state: -1, team1: null, team2: null, state: 0, team1goals: 0, team2goals: 0});
        }
        setGames(gmes)
        console.log("its zero games anyway")
        return
      }
      console.log(response.data)
      setGames(response.data)
    })
  };

  const loadTable = async (id) => {
    for (var member in registredTeams) delete registredTeams[member];
    for (var member in table) delete table[member];

    await axios.get('/api/campeonatos/'+props.contestid+'/tabelas').then(async response => {
      for (var member in registredTeams) delete registredTeams[member];
      for (var member in table) delete table[member];
      const data = response.data;
      response.data.forEach( (v, k) => {
        table[v.id] = [];
        registredTeams[v.id] = [];
      })
        
      await axios.get('/api/campeonatos/'+props.contestid+'/tabelas/valores').then(response => {
        response.data.forEach((v,k) => {
          table[v.tableid].push(v)
          registredTeams[v.tableid].push(v)
        });

        Object.keys(table).forEach(k => {
          for(var i = 0; i < props.contest.teamsAmount; i++) {
            if(!table[k][i]) {
              table[k][i] = {
                "points": 0,
                "wins": 0,
                "loses": 0,
                "ties": 0,
                "teamid": -1,
                "tableid": i,
              }
              registredTeams[k].push(-1)
            }
          }
          
          const x = {...registredTeams}
          for(var i in x) {
            if(x[i].length > props.contest.teamsAmount) {
              x[i].splice(0, x[i].length-props.contest.teamsAmount)
            }
          }

          setRegistredTeams(x)
          setReadyRegistredTeams(!readyRegistredTeams)
        })
      })
    })
  }

  const loadTeams = async () => {
    await axios.get('/api/campeonatos/'+props.contestid+'/times').then(response => {
      response.data.forEach((v, k) => {
        teams[v.id] = v;
      })
    });
    setLoading(false)
  }

  useEffect(() => {
    if(!x) {
      x = true
      return
    }
    loadGames()
    loadTable(props.contestid)
    loadTeams();
//    x = false
  }, [props.contestid])

  useEffect(() => {
    loadTable(props.contestid)
    loadGames()
    loadTeams();
  }, [])
  
  if(loading) {
    return <h1>Loading...</h1>
  }

  const contestType = {
    0: <GruposMataContest 
      games={props.games}
      setGames={props.setGames}
      table={table}
      registredTeams={registredTeams}
      readyRegistredTeams={readyRegistredTeams}
      setRegistredTeams={setRegistredTeams}
      contest={props.contest} />,

    1: <GruposMataContest 
      games={props.games}
      setGames={props.setGames}
      readyRegistredTeams={readyRegistredTeams}
      table={table}
      registredTeams={registredTeams}
      setRegistredTeams={setRegistredTeams}
      contest={props.contest} />,
    
    2: <GruposMataContest 
      games={games}
      setGames={props.setGames}
      table={table}
      setRegistredTeams={setRegistredTeams}
      readyRegistredTeams={readyRegistredTeams}
      registredTeams={registredTeams}
      contest={props.contest} />,
    
    3: <GruposMataContest 
      games={games}
      setGames={props.setGames}
      readyRegistredTeams={readyRegistredTeams}
      setRegistredTeams={setRegistredTeams}
      table={table}
      registredTeams={registredTeams}
      contest={props.contest} />,
    
    4: <GruposMataContest 
      games={props.games}
      setGames={props.setGames}
      readyRegistredTeams={readyRegistredTeams}
      setRegistredTeams={setRegistredTeams}
      table={table}
      registredTeams={registredTeams}
      contest={props.contest} />,
    }

  if(props.contest.winner > 0 && !teams[props.contest.winner]) {
    return "<h1>Loading...</h1>";
  }
  return (
    <div>
      {
        props.contest.state == 2 ? <WinnerWarring team={teams[props.contest.winner]} /> : null
      }

      <div className="cpage-container">
        {
          props.contest.state == 0 && <ContestConfig setContest={props.setContest} contest={props.contest}/>
        }

        <div className="contest-container">
          {
            contestType[props.contest.format]
          }

          <GamesContainer 
            contest={props.contest}
            setContest={props.setContest}
            games={games}
            cupid={props.cupid}
            registredTeams={registredTeams}
            setGames={setGames}
            teams={teams}
          />
        </div>
      </div>
    </div>
  );
}

export default Contest;
