import AuthForm from '../components/forms/AuthForm'
import './Page.css'

const AuthPage = (props) => {
  return (
    <div className="gpage">
      <AuthForm setUser={props.setUser}/>
    </div>
  );
}

export default AuthPage;
