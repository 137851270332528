import './ContestConfig.css';
import Modal from 'react-modal';
import SimpleButton from '../buttons/SimpleButton';
import FormButton from '../buttons/FormButton';
import axios from 'axios';
import { useState, useEffect } from 'react';

const ContestConfig = (props) => {

  const [popupOpen, setPopupOpen] = useState(false);

  const sendContestConfig = async () => {
    await axios.put('/api/campeonatos/'+props.contest.id, props.contest);
    setPopupOpen(false);
  }

  const updateContestConfig = (e) => {
    var x = {...props.contest}
    x[e.target.name] = e.target.value
    props.setContest(x)
  }

  const modalStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.29)',
    },
    content: {
      maxWidth: '516px',
      margin: 'auto',
      border: 'none',
      backgroundColor: '#141b2d'//'rgba(0,0,0,.35)'
    }
  }

  return (
    <div className="contest-config">
    <Modal isOpen={popupOpen}
           style={modalStyle}
           contentLabel="Regras"
           onRequestClose={() => setPopupOpen(false)}>


    <div className="contest-laws">
      <h1>Configurar regras</h1>
      <div className="contest-law">
        <h1>Suspensoes</h1>

        <div class="cntst-inpt">
          <span>Numero cartoes amarelos</span>
          <input value={props.contest.yellowCards} name="yellowCards" onChange={updateContestConfig}type="number"/>
        </div>
        
        <div class="cntst-inpt">
          <span>Jogos suspensos por amarelo</span>
          <input type="number" name="yellowCardGames" placeholder="n cartoes amarelos" value={props.contest.yellowCardsGames} onChange={updateContestConfig}/>
        </div>
        
        <div class="cntst-inpt">
          <span>Jogos suspensos por vermelho</span>
          <input type="number" name="redCardGames" placeholder="n cartoes amarelos" value={props.contest.redCardGames} onChange={updateContestConfig}/>
        </div>
      </div>
      <div className="contest-law">
        <h1>Pontuacao de Equipes</h1>

        <div class="cntst-inpt">
          <span>Vitoria:</span>
          <input type="number" name="winPoints" onChange={updateContestConfig} placeholder="Vitoria" value={props.contest.winPoints} />
        </div>
        
        <div class="cntst-inpt">
          <span>Empate:</span>
          <input type="number" name="tiePoints" placeholder="Empate" onChange={updateContestConfig} value={props.contest.tiePoints} />
        </div>
        
        <div class="cntst-inpt">
          <span>Derrota:</span>
          <input type="number" name="losePoints" placeholder="Derrota" onChange={updateContestConfig} value={props.contest.losePoints} />
        </div>
      </div>
      
      <FormButton onClick={sendContestConfig} text="Definir regras" />

    </div>
   </Modal>

      <SimpleButton onClick={ () => setPopupOpen(!popupOpen)} text="Regras"/>
    </div>
  )
}

export default ContestConfig;
