
import { ReactComponent as PencilSvg } from '../../UpdateGameForm/PencilSvg.svg';
import Modal from 'react-modal';
import { useState, useEffect } from 'react';
import PictureInput from '../../PictureInput/PictureInput'; 
import FormButton from '../../buttons/FormButton'; 
import axios from 'axios';

const UpdatePlayerForm = (props) => {

  const [popup, setPopup] = useState(false);
  const [player, setPlayer]= useState(props.player);

  const onChange = (e) => {
    var x = {...player};
    x[e.target.name] = e.target.value;
    props.setPlayer(x)
    setPlayer(x)
  }
  const savePlayer = (e) => {
    axios.put('/api/campeonatos/'+props.contestid+'/inscricoes/'+player.id, player);
    setPopup(false)
  }
  
  const modalStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.29)',
    },
    content: {
      maxWidth: '420px',
      margin: 'auto',
      border: 'none',
      background: 'rgb(9, 16, 35) none repeat scroll 0% 0%'//'rgba(0,0,0,.35)'
    }
  };

  return (
  <div>
    <PencilSvg onClick={() => setPopup(true)}/>
  
  <Modal isOpen={popup} 
          style={modalStyle}
          onRequestClose={() => setPopup(!popup)}>

    <div className="updt-inpts" style={{ margin: 'auto'}}>
        {
          player.picture != null && 
            <PictureInput />
        }
        <div className="updt-inpt">
          <span>Nome:</span>
          <input 
            onChange={onChange}

          value={player.name} name="name" type="text" placeholder="nome" />
        </div>
        <div className="updt-inpt">
          <span>Data nascimento:</span>
          <input 
            onChange={onChange}
          value={player.birth} name="birth" type="date" placeholder="data nascimento" />
        </div>
        {
          player.cpf != null && 
          <div className="updt-inpt">
          <span>CPF:</span>
          <input 
            onChange={onChange}
          value={player.cpf} name="cpf" type="text" placeholder="cpf" />
        </div>
        }
        {
          player.rg != null && 
          <div className="updt-inpt">
          <span>RG:</span>
          <input 
            onChange={onChange}
          value={player.rg} name="rg" type="text" placeholder="rg" />
        </div>
        }
        {
          player.phone != null && 
          <div className="updt-inpt">
          <span>Telefone:</span>
          <input 
            onChange={onChange}
          value={player.phone} name="phone" type="phone" placeholder="numero celular"  />
        </div>
        }
        {
          player.certNasc != null && 
          <div className="updt-inpt">
          <span>Certidao nascimento:</span>
          <input 
            onChange={onChange}
          value={player.certNasc} name="certNasc" type="text" placeholder="certidao nascimento" />
        </div>
        }
        {
          player.tshirt != null && 
          <div className="updt-inpt">
          <span>Camisa:</span>
          <input 
            onChange={onChange}
          value={player.tshirt} name="tshirt" type="int" placeholder="certidao nascimento" />
        </div>
        }
    
        <FormButton
          onClick={savePlayer}
          text="Salvar"/>
    </div>
    </Modal>
    </div>
  )
}

export default UpdatePlayerForm;
