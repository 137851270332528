import Modal from 'react-modal';
import { HOST } from '../../constant/constant';
import Warring from '../../components/Warring/Warring';
import SimpleButton from '../../components/buttons/SimpleButton';
import PictureInput from '../../components/PictureInput/PictureInput'; 
import { onFormInput } from '../../utils/FormInput.js'
import FormButton from '../buttons/FormButton';
import '../forms/CreatorForm.css'

import axios from 'axios';
import { useState, useRef, useEffect } from 'react';

const RegisterPlayer = (props) => {
  
  const DEFAULT_PICTURE_SHIELD = HOST+"/public/image/defaultshield.png";
  
  const [contestConfig, setContestConfig] = useState({})
  const [picture, setPicture] = useState("")
  const [error, setError] = useState("")
  const [ registerPlayerContainer, setRegisterPlayerContainer] = useState(false);

  const loadContestConfig = () => {
    axios.get('/api/campeonatos/'+props.contestid+'/times/config').then(r => {
      setContestConfig(r.data)
    });
  }
  useEffect(() => {
    loadContestConfig()
  }, []);

  const onFormSubmit = (e) => {
    var data = onFormInput(e, false) 
    data['teamid'] = Number(props.teamid); 
    if(contestConfig.picture == 2 && picture == "") {
      setError("A foto é nescessaria para o registro do jogador")
      return
    }
    axios.post('/api/campeonatos/'+props.contestid+'/times/'+props.teamid+'/jogadores', data).then(response => {
      console.log('sending picture:')
      console.log(picture)
      
      console.log(response.data.id)
      const fileData = new FormData();
      fileData.append('picture', picture);
      
      axios.post('/api/jogadores/'+response.data.id+'/picture', fileData);
      data['id'] = response.data.id;
      data['picture'] = response.data.picture;
      props.addPlayer(data)
    });
    e.preventDefault()
    closeContainer()
  }
  const closeContainer = () => {
    setRegisterPlayerContainer(false)  
  }

  const registerPlayerStyle = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.29)',
    },
    content: {
      maxWidth: '420px',
      margin: 'auto',
      border: 'none',
      background: 'rgb(9, 16, 35) none repeat scroll 0% 0%'//'rgba(0,0,0,.35)'
    }
  };

  console.log("ContestConfig:")
  console.log(contestConfig)

  return (
    <div>
      <Modal isOpen={registerPlayerContainer}
              transparent={true}
              tranparency='50%'
              style={registerPlayerStyle}
              contentLabel="example model"
              onRequestClose={closeContainer}>
        <h1 style={{ textAlign: 'center' }}>Registrar jogador</h1>

        <div className="updt-inpts" style={{ margin: 'auto'}}>
            {
              contestConfig.picture > 0 && 
                <PictureInput setImage={setPicture} required={contestConfig.picture == 2} />
            }
          <form onSubmit={onFormSubmit}>

            <div className="updt-inpt">
              <span>Nome:</span>
              <input name="name" type="text" placeholder="nome" required/>
            </div>
            <div className="updt-inpt">
              <span>Data nascimento:</span>
              <input name="birth" type="date" placeholder="data nascimento" required/>
            </div>
            {
              contestConfig.cpf > 0 && 
              <div className="updt-inpt">
              <span>CPF:</span>
              <input name="cpf" requiredTxt="Esse parametro � nescessario" required={contestConfig.cpf == 2} type="text" placeholder="cpf" />
            </div>
            }
            {
              contestConfig.rg > 0 && 
              <div className="updt-inpt">
              <span>RG:</span>
              <input name="rg" type="text" placeholder="rg" requiredTxt="Esse parametro � nescessario" required={contestConfig.rg == 2} />
            </div>
            }
            {
              contestConfig.phone > 0 && 
              <div className="updt-inpt">
              <span>Telefone:</span>
              <input name="phone" type="phone" placeholder="numero celular" requiredTxt="Esse parametro � nescessario" required={contestConfig.phone == 2} />
            </div>
            }
            {
              contestConfig.certNasc > 0 && 
              <div className="updt-inpt">
              <span>Certidao nascimento:</span>
              <input name="certNasc" type="text" placeholder="certidao nascimento" requiredTxt="Esse parametro � nescessario" required={contestConfig.certNasc == 2} />
            </div>
            }
            {
              contestConfig.tshirt > 0 && 
              <div className="updt-inpt">
              <span>Camisa:</span>
              <input name="tshirt" type="int" requiredTxt="Esse parametro � nescessario" required={contestConfig.tshirt == 2} placeholder="certidao nascimento" />
            </div>
            }

            { error != "" && <div className="error"><span>{error}</span></div> }

            <FormButton
              text="Registrar jogador"/>
          </form>
       </div>
     </Modal>
     <div style={{ textAlign: 'center', marginTop: '20px' }}>
     { props.playersAmount >= contestConfig.maxPlayersCount ?
       <Warring message={"Time esta cheio de jogadores"} />
      :
       <SimpleButton 
         style={{ width: '70%', borderRadius: '2px', padding: '11px', fontSize: '1rem'}}
         onClick={() => setRegisterPlayerContainer(!registerPlayerContainer)}
         text="ADICIONAR JOGADOR"
       />}
     </div>
   </div>
  );
}

export default RegisterPlayer;
