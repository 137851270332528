import './RegisterTeamMenu.css';
import { Link } from 'react-router-dom';
import FormButtonC from '../buttons/FormButtonC';

const RegisterTeamMenu = (props) => {

  return (
    <div className="register-menu">
      <div className="register-menu-option">
        <div className="register-menu-option-title">
          <span>Criar times</span>
        </div>
        <div className="register-menu-option-desc">
          <span>Criar novo time com novas informações</span>
        </div>
        
        <div style={{marginTop: '90px'}}>
          <Link to={"/copas/"+props.cupid+"/times/criar"} state={{ contests: props.contests , contestid: props.contestid }}>
            <FormButtonC>
              <span>Criar</span>
            </FormButtonC>
          </Link>
        </div>
      </div>

      <div className="register-menu-option">
        
        <div className="register-menu-option-title">
          <span>Importar times</span>
        </div>
        <div className="register-menu-option-desc">
          <span>Importar time ja existentes de outros campeonatos registrados em tua conta</span>
        </div>

        <div style={{marginTop: '90px'}}>
          <Link to={"/campeonatos/"+props.contestid+"/times/importar"}>
            <FormButtonC style={{ background: 'rgb(35, 35, 204)'}}>
              <span>Importar</span>
            </FormButtonC>
          </Link>
        </div>
      </div>

    </div>
  );
};

export default RegisterTeamMenu;
