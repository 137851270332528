
import { useState } from 'react';

const ExtraOptions = (props) => {
  console.log(props.value == 2)
  console.log("value:"+props.value)
  const [teamsAmount, setTeamsAmount] = useState(2);
  
  const onChange = (e) => {
    if(e.target.value < 2) return
    setTeamsAmount(e.target.value)
  }

  if(props.value == 3) {
    return (  
      <div className="updt-inpt-flex">
        <div>
          <span>Numero times:</span>
          <input name="teamsAmount" min="2" className="int" type="number" placeholder="numero times" />
        </div>
        <div>
          <span>Numero grupos:</span>
          <input min="1" defaultValue="1" name="groupsAmount" className="int" type="number" placeholder="numero grupos" />
        </div>
      </div>
    );
  } else if(props.value == 1) {
    return (
    <div>
      <div className="updt-inpt">
        <span>Quando inicia mata-mata:</span>
        <select name="whenEliminatories" className="int" required>
          { teamsAmount < 2 && <option style={{ color: "red"}}> Times insuficientes</option> }
          { teamsAmount >= 2 && <option value="2">Final</option> }
          { teamsAmount >= 4 && <option value="4">Semifinal</option> }
          { teamsAmount >= 8 && <option value="8">Quartas de finais</option> }
          { teamsAmount >= 16 && <option value="16">Oitavas de finais</option> }
        </select>
      </div>
      <div className="updt-inpt">
        <span>Numero de times:</span>
        <input min="2" style={{ width: '40%' }} className="int" name="teamsAmount" value={teamsAmount} onChange={onChange} type="number" placeholder="numero times" />
      </div>
      </div>
    );
  } else {
    return (  
      <div className="updt-inpt">
        <span>Numero de times:</span>
        <input min="2" style={{ width: '40%' }} className="int" name="teamsAmount" value={teamsAmount} onChange={onChange} type="number" placeholder="numero times" />
      </div>
    );
  }
}

const ContestCreatorInputs = () => {
  const onChange = (event) => {
    console.log(event.target.value);
    setValue(event.target.value);
  };
  
  const [value, setValue] = useState(0); 

  return (
    <div>
      <div className="updt-inpt">
        <span>Nome da categoria</span>
        <input name="name" type="text" required />
      </div>
      <div className="updt-inpt">
        <span>Categoria:</span>
        <select name="category" className="int" required>
          <option value="1">Infanto-juvenil</option>
          <option value="2">Adulto</option>
          <option value="3">Master</option>
        </select>
      </div>
      
      <div className="updt-inpt">
        <span>Genero:</span>
        <select name="gender" className="int" required>
          <option value="1">Masculino</option>
          <option value="0">Feminino</option>
        </select>
      </div>

      <div className="updt-inpt">
        <span>Modalidade: </span>
        <select name="modality" className="int" required>
          <option value="1">Campo</option>
          <option value="2">Fut7</option>
          <option value="3">Futsal</option>
          <option value="4">Fifa/PES</option>
        </select>
      </div>
     
      <div className="updt-inpt" onChange={onChange} required>
        <span>Formato:</span>
        <select name="format" className="int">
          <option value="0">Pontos corridos</option>
          <option value="1">Pontos Corridos e mata mata</option>
          <option value="3">Grupos e Mata Mata</option>
        </select>
      </div>

      <ExtraOptions
        value={value}
      />
    </div>
  )
}

export default ContestCreatorInputs;
