import axios from 'axios';
import { HOST } from '../../constant/constant';
import { useState, useRef, useEffect } from 'react';

import SimpleButton from '../../components/buttons/SimpleButton';
import FormButton from '../../components/buttons/FormButton';
import { useParams } from 'react-router-dom';
import '../TeamsPage/TeamsPage.css';
import { useNavigate } from 'react-router-dom'

const ImportTeamPage = (props) => {
  
  const { contestid } = useParams();
  const [userTeams, setUserTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState({})
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  const loadUserTeams = () => {
    axios.get('/api/users/'+props.user.id+'/times').then(response => {
      setUserTeams(response.data);
      setLoading(false)
    })
  }

  const removeSelectedTeam = (id) => {
    var x = {...selectedTeams}
    delete x[id]
    console.log('removing')
    console.log(x)
    setSelectedTeams(x)
  }

  const selectTeam = (v) => {
    var x = {...selectedTeams}
    x[v] = { teamid: v, contestid: contestid };
    setSelectedTeams(x)
  }

  const sendSelectedTeams = (v) => {
    var data = [];
    for(var i in selectedTeams) {
      data.push(selectedTeams[i])
    }
    axios.post('/api/campeonatos/'+contestid+'/times/import', data)
    navigate('/copas/'+props.cupid+'/times')
  }

  useEffect(() => {
    loadUserTeams()
  }, [])
  
  if(loading) {
    return <h1>Loading...</h1>;
  }

  return (

    <div className="gpage">
      <div className="psimple-container">
        <FormButton 
          text="Importar times selecionados"
          onClick={sendSelectedTeams}
        />
        <div className="teams">
          { userTeams.map((v) => {
          return <div className="team">
            <div className="team-image">
              <img src={HOST+"/public/image/"+v.picture} />
            </div>
            <div className="team-title">
              <span>{v.name}</span> 
            </div>
            {selectedTeams[v.id] ? 
              <SimpleButton
                text="remove"
                onClick={() => removeSelectedTeam(v.id)}
              />
              : <FormButton
                  text="add"
                  onClick={() => selectTeam(v.id)}/>
              }
          </div>
          })

          }
        </div>
      </div>
    </div>
  );

  
}

export default ImportTeamPage;
