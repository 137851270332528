

const Warring = (props) => {
  return (
    <div className="warring">
      <span>{props.message}</span>
    </div>
  );
}
export default Warring;
