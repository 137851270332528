import './Toggle.css';

const Toggle = (props) => {
    return (
    <label className="switch">
      <input name={props.name} checked={props.checked} onChange={props.onChange} type="checkbox"/>
      <span className={props.q ? "slider" : "slider round"}></span>
    </label>
  );
}

export default Toggle;
