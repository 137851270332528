import FinishGameForm from '../../components/FinishGameForm/FinishGameForm';
import '../Page.css';
import { useState, useEffect } from 'react';

import axios from 'axios';

import { useParams } from 'react-router-dom';
const FinishGamePage = (props) => {

  const { cupid, gameid } = useParams();
  const [ loading, setLoading ] = useState(true);

  const [game, setGame] = useState({});
  const [teams, setTeams] = useState([]);

  const loadGame = async () => {
    console.log("games: ")
    console.log(props.games)
    let w = false
    var games = props.games;
    for(var i in props.games) {
      console.log("i: "+i);
      if(props.games[i].id == gameid) {
        console.log(games[i])
        setGame(games[i])
        w = true
        break
      }
    }
    
    if(!w) {  
      await axios.get('/api/jogos/'+gameid).then(response => {
        game['g'] = response.data[0];
        setGame(response.data[0]);
      })

    }
  }

  const loadGameTeams = async () => {
    await axios.get('/api/jogos/'+gameid+'/times').then(response => {
      const data = response.data
      
      console.log('games po:')
      console.log(game)
      if(data[0].id != game.g.team1) {
        var x = data[0]
        var y = data[1]
        data[1] = x;
        data[0] = y
      }
      setTeams(data)
      setLoading(false)
    })
  }

  useEffect(() => {
    loadGame()
    loadGameTeams()
  }, [])

  console.log("game:")
  console.log(game)

  if(loading || teams.length == 0 || !game ) {
    return <h1>Loading...</h1>
  }

  return (
    <div className="qpage">
      <div className="qpage-container" style={{ textAlign: 'center' }}>
        <FinishGameForm
          cupid={cupid}
          teams={teams}
          game={game}
        />
      </div>
    </div>
  )
}

export default FinishGamePage;
