import './Contests.css';
import { ReactComponent as PlusSvg } from './Plus.svg';

import { Link } from 'react-router-dom';
const Contests = (props) => {
  return (
    <div className="contests-container">
      {props.contests.map((r) => {
        return (
          <div className="contests"
            onClick={() => props.setContestid(r.id)}
            style={ props.contestid == r.id ? 
              { borderBottom: '4px solid #07022d'} 
              :
              null}

          >
            <span 
                  style={ props.contestid == r.id ? 
                    { color: 'white'} 
                    : 
                    {color: 'gray'}}
                 >{r.name}</span>

          </div>
        )
      })}
      <Link to={"/copas/"+props.cupid+"/campeonatos/criar"}>
        <div className="contests">
          <div className="add-btn">

              <PlusSvg /> 

          </div>
        </div>
      </Link>
    </div>
  );
}

export default Contests;

