import axios from 'axios';
import './TeamPage.css';

import SimpleButton from '../../components/buttons/SimpleButton';

import Players from '../../components/Players/Players';
import { useState, useEffect } from 'react';
import { useLocation ,useParams, Link} from 'react-router-dom';
import RegisterPlayer from '../../components/RegisterPlayer/RegisterPlayer';

const TeamPage = (props) => {

  const location = useLocation();

  const { teamid, contestid } = useParams();
  const [ team, setTeam] = useState({});
  const [ players, setPlayers ] = useState([]);
  const [ loading, setLoading ] = useState(true);


  const loadPlayers = () => {
    axios.get('/api/campeonatos/'+contestid+'/times/'+teamid+'/jogadores').then(response => {
      setPlayers(response.data)
    })
  }

  const loadTeam = () => {
    axios.get('/api/times/'+teamid).then(r => {
      setTeam(r.data[0])
    })
  }

  const addPlayer = (data) => {
    var cp = [...players]
    cp.push(data)
    setPlayers(cp)
  }

  const deletePlayer = (id) => {
    axios.delete('/api/campeonatos/'+contestid+'/jogadores/'+players[id].playerid)
      .then(response => {
        var cp = [...players]
        cp.splice(id, 1);
        setPlayers(cp)
      });
  }

  useEffect(() => {
    loadPlayers() 
    loadTeam()
    setLoading(false)
  }, []);

  if(loading || team.length <= 0) {
    return <h1>Loading...</h1>;
  }

  console.log("team:")
  console.log(team)
  return (
    <div className="gpage" style={{ width: '100%', marginTop: '25px'}} >



      <div style={{ width: '100%' }}>
        <div className="tm">
          <div className="tm-img">
            <img src={'/public/image/'+team.picture} />
          </div>
          <span>{team.name}</span>
        </div>
        <div className="sub-lnk">
          <div className="lnk">
            <div className="lnk-l">
              <span>Link de inscricao:</span>
            </div>
            <div className="link-d">
              <input value={
            '/campeonatos/'+contestid+'/times/'+teamid+'/inscricoes'
              } />

              <Link to={"/campeonatos/"+contestid+"/times/"+teamid+"/inscrever"}>FACA ENTAO</Link>
            </div>
          </div>
          
          <div className="lnk-op">
            
            <div className="lnk-c">
              <SimpleButton style={{ border: '1px solid #219d2e', background: 'none', padding: '10px'}} text="COPIAR LINK" />
            </div>

          </div>
        </div>
      </div>
      
      <h1 style={{ textAlign: 'center', marginTop: '15px'}}>{players.length > 1 
        ? players.length+" jogadores" 
        : "1 jogador" }</h1>



      <RegisterPlayer
        teamid={teamid}
        playersAmount={players.length}
        contestid={contestid}
        addPlayer={addPlayer}
      />


      <Players  
        players={players}
        deletePlayer={deletePlayer}
        canUpdate={true}
        canDelete={true}
      />
    </div>
  );

}

export default TeamPage;
